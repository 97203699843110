// src/components/homePage/ProductHighlights.js
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/bundle"; // Imports the full bundle for side effects and module registration
import "swiper/css/bundle";
import { axiosInstance } from "../../api/axiosConfig";
import "./ProductHighlights.scss";

const ProductHighlights = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axiosInstance.get("/products/highlights/");
        setProducts(response.data);
      } catch (error) {
        console.error("Error fetching product highlights:", error);
      }
    };
    fetchProducts();
  }, []);

  return (
    <div className="product-highlights">
      {products.length ? (
        <Swiper
          spaceBetween={30}
          slidesPerView={3}
          navigation
          pagination={{ clickable: true }}
          loop
          autoplay={{ delay: 4000 }}
          breakpoints={{
            320: { slidesPerView: 1 },
            640: { slidesPerView: 2 },
            1024: { slidesPerView: 3 },
          }}
          className="highlights-swiper"
        >
          {products.map((product) => (
            <SwiperSlide key={product.id}>
              <div className="product-card">
                <div className="image-container">
                  <img src={product.thumbnail_image} alt={product.name} />
                </div>
                <div className="product-info">
                  <h3 className="product-title">{product.name}</h3>
                  <p className="product-price">£{product.price}</p>
                  <a href={`/product/${product.id}`} className="product-btn">
                    View Details
                  </a>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <p>Error fetching product highlights</p>
      )}
    </div>
  );
};

export default ProductHighlights;