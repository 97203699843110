import React, { useState, useEffect, useRef, useMemo } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "./ProductImageGallery.scss";

const ProductImageGallery = ({ images }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const thumbnailContainerRef = useRef(null);

  // Memoize slides to avoid re-creation on each render (optional, but safe)
  const slides = useMemo(() => {
    return images.map((src) => ({ src }));
  }, [images]);

  // Auto-scroll active thumbnail into view
  useEffect(() => {
    if (thumbnailContainerRef.current) {
      const activeThumb =
        thumbnailContainerRef.current.querySelector(".active");
      if (activeThumb) {
        activeThumb.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    }
  }, [selectedIndex]);

  // Optional safety check for out-of-range index
  useEffect(() => {
    if (selectedIndex < 0) setSelectedIndex(0);
    if (selectedIndex >= slides.length) setSelectedIndex(slides.length - 1);
  }, [selectedIndex, slides.length]);

  return (
    <div className="product-image-gallery">
      {/* Main image (click => open Lightbox) */}
      <div className="main-image" onClick={() => setIsOpen(true)}>
        <img src={images[selectedIndex]} alt="Selected Product" />
      </div>

      {/* Thumbnails */}
      <div className="thumbnail-images" ref={thumbnailContainerRef}>
        {images.map((src, idx) => (
          <img
            key={idx}
            src={src}
            alt={`Thumbnail ${idx}`}
            className={selectedIndex === idx ? "active" : ""}
            onClick={() => setSelectedIndex(idx)}
          />
        ))}
      </div>

      {/* Lightbox in "semi-uncontrolled" mode */}
      {isOpen && (
        <Lightbox
          open={isOpen}
          close={() => setIsOpen(false)}
          slides={slides}
          // Removing the index prop => Lightbox manages which slide is active internally
          onSlideChange={(currentIndex) => {
            setSelectedIndex(currentIndex);
          }}
        />
      )}
    </div>
  );
};

export default ProductImageGallery;
