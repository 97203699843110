import React, { useState } from "react";
import { FaBolt } from "react-icons/fa";
import "./ExpressCheckout.scss";

const ExpressCheckout = ({ product, onExpressCheckout }) => {
  const [savePayment, setSavePayment] = useState(false);

  return (
    <div className="express-checkout">
      {product?.bestSeller && (
        <div className="best-seller-badge" aria-label="Best Seller">
          <FaBolt className="best-seller-icon" /> Best Seller
        </div>
      )}

      <label className="save-payment-checkbox">
        <input
          type="checkbox"
          checked={savePayment}
          onChange={() => setSavePayment(!savePayment)}
        />
        Save payment method for faster checkout
      </label>

      <button
        className="express-checkout-button"
        onClick={() => {
          if (typeof onExpressCheckout === "function") {
            onExpressCheckout(savePayment);
          } else {
            console.error("❌ onExpressCheckout is not a function!");
            alert(
              "⚠️ Express Checkout function is missing. Please try again later.",
            );
          }
        }}
        aria-label="Express Buy Now"
      >
        <FaBolt className="checkout-icon" /> Buy Now with Express Checkout
      </button>
    </div>
  );
};

export default ExpressCheckout;
