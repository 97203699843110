// src/components/productDetail/SeoMetaTags.jsx
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

const SeoMetaTags = ({ product }) => {
  if (!product) return null;

  const productUrl = `https://ruothstore.com/product/${product.slug || ""}`;
  const productImage =
    product.gallery_images?.[0] || "https://ruothstore.com/default-image.jpg";
  const productDescription =
    product.description?.slice(0, 160) ||
    "Discover premium kitchen decor and recipe templates at Ruoth.";

  const jsonLd = {
    "@context": "http://schema.org",
    "@type": "Product",
    name: product.name || "Ruoth Product",
    image: productImage,
    description: product.description || "",
    sku: product.sku || "",
    brand: {
      "@type": "Brand",
      name: "Ruoth",
    },
    offers: {
      "@type": "Offer",
      priceCurrency: "GBP",
      price: product.price || "0.00",
      availability:
        product.stock > 0
          ? "http://schema.org/InStock"
          : "http://schema.org/OutOfStock",
      url: productUrl,
    },
    aggregateRating: product.average_rating
      ? {
          "@type": "AggregateRating",
          ratingValue: product.average_rating.toFixed(1),
          reviewCount: product.review_count || 0,
        }
      : undefined,
  };

  return (
    <HelmetProvider>
      <Helmet>
        {/* Dynamic Page Title */}
        <title>
          {product.name
            ? `${product.name} | Ruoth`
            : "Ruoth | Premium Kitchen Decor & Recipe Cards"}
        </title>

        {/* Meta Descriptions */}
        <meta name="description" content={productDescription} />
        <meta
          name="keywords"
          content={`kitchen decor, recipe cards, printables, digital downloads, ${product.name}`}
        />

        {/* Canonical URL (Best Practice for SEO) */}
        <link rel="canonical" href={productUrl} />

        {/* Open Graph Meta Tags (For Social Media) */}
        <meta property="og:type" content="product" />
        <meta property="og:title" content={`${product.name} | Ruoth`} />
        <meta property="og:description" content={productDescription} />
        <meta property="og:image" content={productImage} />
        <meta property="og:url" content={productUrl} />
        <meta property="og:site_name" content="Ruoth" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${product.name} | Ruoth`} />
        <meta name="twitter:description" content={productDescription} />
        <meta name="twitter:image" content={productImage} />

        {/* Structured Data (JSON-LD for Rich Snippets) */}
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      </Helmet>
    </HelmetProvider>
  );
};

export default SeoMetaTags;
