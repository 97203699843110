// src/components/homePage/ContentHubPreview.js
import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { axiosInstance } from "../../api/axiosConfig";
import "bootstrap/dist/css/bootstrap.min.css";
import "./ContentHubPreview.scss";

const ContentHubPreview = () => {
  const [contents, setContents] = useState([]);

  useEffect(() => {
    const fetchContents = async () => {
      const response = await axiosInstance.get("/content/content/?latest=true");
      setContents(response.data);
    };
    fetchContents();
  }, []);

  return (
    <div className="content-hub-preview">
      {" "}
      {/* Changed Row to div for flex layout */}
      {contents.map((content) => (
        <div key={content.id} className="product-card">
          {" "}
          {/* Changed Col to div for fixed width */}
          <Card>
            <Card.Img
              variant="top"
              src={content.cover_image}
              className="product-image"
            />
            <Card.Body className="card-body">
              <Card.Title className="card-title">{content.title}</Card.Title>
              <Card.Text className="card-text">{content.summary}</Card.Text>
              <a href={`/content/${content.slug}`} className="btn btn-primary">
                Read More
              </a>
            </Card.Body>
          </Card>
        </div>
      ))}
    </div>
  );
};

export default ContentHubPreview;
