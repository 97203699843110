import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import { FaCheckCircle, FaFire, FaAward, FaHeart, FaGift } from "react-icons/fa"; // ✅ Replacing emojis with icons
import Slider from "react-slick";
import ProductDescription from "../productDetail/ProductDescription";
import ProductFeatures from "../productDetail/ProductFeatures";
import ProductAdvantages from "../productDetail/ProductAdvantages";
import ProductBenefits from "../productDetail/ProductBenefits";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ProductDetailsCarousel.scss";

const ProductDetailsCarousel = ({ product }) => {
  const slidesData = [
    {
      key: "hook",
      title: "A Chef’s Tools Should Be as Precise as Their Craft.",
      interactionText: "Tap to See Why Precision Matters",
      content: (
        <div className="instant-value"> {/* ✅ Wrap everything */}
          <img 
            src={product.thumbnail_image || "/default-thumbnail.webp"} 
            alt={product.name || "Ruoth Professional Kitchen Tools"}
            loading="eager" 
            fetchpriority="high"
          />
          <p><strong>Trusted by 30,000+ chefs worldwide.</strong></p>
          <p>Your tools define your results. The right ones enhance precision, efficiency, and creativity.</p>
          <p className="swipe-hint">Swipe → to see more</p>
        </div>
      ),
      microReward: <FaCheckCircle /> 
    },
    {
      key: "description",
      title: "The Difference Between Ordinary & Exceptional Tools.",
      interactionText: "Swipe to Compare Performance",
      content: <ProductDescription description={product.description} />,
      microReward: <FaFire /> // ✅ Professional replacement for 🔥
    },
    {
      key: "features",
      title: "Most Tools Are Made for the Masses. Ours Are Made for Experts.",
      interactionText: "Click to Reveal What Sets Us Apart",
      content: <ProductFeatures features={product.features} />,
      microReward: <FaAward /> // ✅ Professional replacement for 🎖️
    },
    {
      key: "advantages",
      title: "Imagine Creating Without Limitations.",
      interactionText: "Swipe to See Excellence in Motion",
      content: <ProductAdvantages advantages={product.advantages} />,
      microReward: <FaHeart /> // ✅ Professional replacement for 💖
    },
    {
      key: "benefits",
      title: "Join Thousands of Culinary Experts Who Trust Ruoth.",
      interactionText: "See Why Ruoth Today",
      content: <ProductBenefits benefits={product.benefits} />,
      microReward: <FaGift /> // ✅ Professional replacement for 🎊
    },
  ];

  const [unlockedSlides, setUnlockedSlides] = useState({});

  const handleUnlock = (index) => {
    setUnlockedSlides((prev) => ({ ...prev, [index]: true }));
  };

  // Custom Next/Prev Arrows
  const NextArrow = (props) => (
    <button {...props} className="slick-next" type="button" aria-label="Next slide" />
  );
  const PrevArrow = (props) => (
    <button {...props} className="slick-prev" type="button" aria-label="Previous slide" />
  );

  const settings = {
    dots: true,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: "ondemand",
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className="product-details-carousel">
      {/* ✅ FIX: Robust Error Handling for Skip Button
      <div className="skip-option">
        <button 
          onClick={() => {
            const target = document.getElementById("product-details");
            if (target) target.scrollIntoView({ behavior: "smooth" });
          }}
        >
          Skip to Product Details
        </button>
      </div> */}

      <Slider {...settings} aria-label="Product details interactive carousel">
        {slidesData.map((slide, index) => (
          <section key={index} className="carousel-slide" aria-labelledby={`slide-${index}`}>
            <h3 id={`slide-${index}`} className="slide-title">{slide.title}</h3>
            
            {/* ✅ FIX: Improved Accessibility for Micro-Rewards */}
            {!unlockedSlides[index] ? (
              <button className="unlock-btn" onClick={() => handleUnlock(index)} aria-label={`Unlock ${slide.title}`}>
                {slide.interactionText}
              </button>
            ) : (
              <div className="slide-content">
                {typeof slide.content === "string" ? <p>{slide.content}</p> : slide.content}
                <span className="micro-reward" role="alert">{slide.microReward}</span>
              </div>
            )}
          </section>
        ))}
      </Slider>
    </div>
  );
};

ProductDetailsCarousel.propTypes = {
  product: PropTypes.shape({
    thumbnail: PropTypes.string, // ✅ Added product thumbnail prop
    name: PropTypes.string, // ✅ Added product name prop
    description: PropTypes.string,
    features: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    advantages: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    benefits: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  }),
};

export default memo(ProductDetailsCarousel);
