import React, { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import "./ProductFAQ.scss";

const ProductFAQ = ({ faqs }) => {
  console.log(faqs);
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFaq = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  if (!faqs || faqs.length === 0) {
    return <p className="faq-empty">No FAQs available for this product.</p>;
  }

  return (
    <div className="product-faq">
      <h3 className="faq-title">Frequently Asked Questions</h3>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`faq-item ${activeIndex === index ? "active" : ""}`}
            aria-expanded={activeIndex === index}
          >
            <button
              className="faq-question"
              onClick={() => toggleFaq(index)}
              aria-controls={`faq-answer-${index}`}
            >
              <span>{faq.question}</span>
              <FiChevronDown className="chevron" />
            </button>
            <div
              id={`faq-answer-${index}`}
              className="faq-answer"
              role="region"
            >
              <p>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductFAQ;
