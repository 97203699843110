import React from "react";
import ContentCard from "./ContentCard";
import "./SpotlightSection.scss";

const SpotlightSection = ({ content }) => (
  <section className="spotlight-section">
    <h2>Spotlight</h2>
    {content && <ContentCard content={content} />}
  </section>
);

export default SpotlightSection;
