// src/components/productDetail/ProductAdvantages.jsx
import React, { useEffect, useState } from "react";

const ProductAdvantages = ({ advantages }) => {
  console.log(
    "🟡 ProductAdvantages received:",
    advantages || "❌ No advantages available.",
  );

  if (!advantages) {
    return (
      <div className="product-advantages">
        <h2>Product Advantages</h2>
        <p>Loading advantages...</p>
      </div>
    );
  }

  return (
    <div className="product-advantages">
      <h2>Product Advantages</h2>
      <div dangerouslySetInnerHTML={{ __html: advantages }} />
    </div>
  );
};

export default ProductAdvantages;
