import React, { useState, useEffect } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import "./FilterSidebar.scss";
import { axiosInstance } from "../../api/axiosConfig";

const FilterSidebar = ({ onChange }) => {
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [collapsed, setCollapsed] = useState(false);

  const initialFilters = {
    query: "",
    category: "",
    priceRange: [0, 100],
    brand: "",
    productType: "",
  };

  const [filters, setFilters] = useState(initialFilters); // 🔄 Applied filters
  const [formState, setFormState] = useState(initialFilters); // 🧪 Draft values

  // Fetch filter options
  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const res = await axiosInstance.get("/products/filters/");
        const { brands, colors, materials, categories } = res.data;

        setBrands(brands.map((name) => ({ name, count: null })));
        setCategories(categories.map((cat) => ({
          id: cat.id,
          name: cat.name,
        })));
        setProductTypes([
          { name: "physical", count: null },
          { name: "digital", count: null },
        ]);
      } catch (err) {
        console.error("❌ Error fetching unified filter options:", err);
      }
    };
    fetchFilters();
  }, []);

  const handleFormChange = (name, value) => {
    setFormState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSliderChange = (name) => (value) => {
    handleFormChange(name, value);
  };

  const handleApplyFilters = () => {
    setFilters(formState);
    onChange(formState); // 🔥 Trigger backend fetch once
  };

  const handleClearFilters = () => {
    setFormState(initialFilters);
    setFilters(initialFilters);
    onChange(initialFilters); // 🔁 Reset filters and fetch all
  };

  return (
    <div className={`filter-sidebar ${collapsed ? "collapsed" : ""}`}>
      <button
        className="filter-sidebar__toggle"
        onClick={() => setCollapsed(!collapsed)}
      >
        <span className="filter-sidebar__toggle-text">
          {collapsed ? "Expand Filters" : "Collapse Filters"}
        </span>
        <svg className="filter-sidebar__toggle-icon" viewBox="0 0 24 24">
          <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/>
        </svg>
      </button>

      <div className="filter-sidebar__content">
        <h2 className="filter-sidebar__heading">Filters</h2>

        {!collapsed && (
          <div className="filter-sidebar__groups">
            {/* Query */}
            <div className="filter-group">
              <label htmlFor="query" className="filter-label">Search</label>
              <input
                type="text"
                name="query"
                id="query"
                value={formState.query}
                onChange={(e) => handleFormChange("query", e.target.value)}
                className="filter-input"
              />
            </div>

            {/* Category */}
            <div className="filter-group">
              <label htmlFor="category" className="filter-label">Category</label>
              <div className="filter-select-wrapper">
                <select
                  name="category"
                  id="category"
                  value={formState.category}
                  onChange={(e) => handleFormChange("category", Number(e.target.value))}
                  className="filter-select"
                >
                  <option value="">All</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Price */}
            <div className="filter-group">
              <label className="filter-label">Price Range (£)</label>
              <div className="filter-slider">
                <Slider
                  range
                  min={0}
                  max={100}
                  value={formState.priceRange}
                  onChange={handleSliderChange("priceRange")}
                />
                <div className="filter-slider-values">
                  £{formState.priceRange[0]} - £{formState.priceRange[1]}
                </div>
              </div>
            </div>

            {/* Brand */}
            <div className="filter-group">
              <label htmlFor="brand" className="filter-label">Brand</label>
              <div className="filter-select-wrapper">
                <select
                  name="brand"
                  id="brand"
                  value={formState.brand}
                  onChange={(e) => handleFormChange("brand", e.target.value)}
                  className="filter-select"
                >
                  <option value="">All</option>
                  {brands.map((brand) => (
                    <option key={brand.name} value={brand.name}>
                      {brand.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Product Type */}
            <div className="filter-group">
              <label htmlFor="productType" className="filter-label">Product Type</label>
              <div className="filter-select-wrapper">
                <select
                  name="productType"
                  id="productType"
                  value={formState.productType}
                  onChange={(e) => handleFormChange("productType", e.target.value)}
                  className="filter-select"
                >
                  <option value="">All</option>
                  {productTypes.map((type) => (
                    <option key={type.name} value={type.name}>
                      {type.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="filter-actions">
              <button onClick={handleApplyFilters} className="btn btn-apply">
                Apply Filters
              </button>
              <button onClick={handleClearFilters} className="btn btn-clear">
                Clear All
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterSidebar;
