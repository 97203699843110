import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../api/axiosConfig";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { FiGift, FiClock } from "react-icons/fi";
import CountdownTimer from "./CountdownTimer";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./ProductUpsell.scss";

const ProductUpsell = () => {
  const navigate = useNavigate();
  const [bundles, setBundles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBundles = async () => {
      try {
        const response = await axiosInstance.get("/products/bundles/");
        setBundles(response.data);
      } catch (err) {
        console.error("Error fetching bundles:", err);
        setError("Upsell bundles unavailable.");
      } finally {
        setLoading(false);
      }
    };

    fetchBundles();
  }, []);

  const handleBundleClick = (id) => navigate(`/bundle/${id}`);

  return (
    <section className="product-upsell">
      <div className="upsell-header">
        <h3>Upgrade Your Order & Save</h3>
      </div>

      {loading ? (
        <p>Loading bundles...</p>
      ) : error ? (
        <p className="error">{error}</p>
      ) : (
        <Swiper
          modules={[Navigation, Pagination]}
          spaceBetween={20}
          slidesPerView={1.2}
          breakpoints={{
            600: { slidesPerView: 2 },
            900: { slidesPerView: 3 },
            1200: { slidesPerView: 4 },
          }}
          pagination={{ clickable: true }}
          navigation
          watchOverflow={true}
          className="upsell-swiper"
        >
          {bundles.map((bundle) => (
            <SwiperSlide key={bundle.id}>
              <div className="bundle-card">
                <img src={bundle.thumbnail_image} alt={bundle.name} />
                <div className="bundle-info">
                  <h4>{bundle.name}</h4>
                  <p>{bundle.description}</p>

                  <div className="pricing">
                    {bundle.discount_price ? (
                      <>
                        <span className="old-price">£{bundle.price}</span>
                        <span className="new-price">£{bundle.discount_price}</span>
                      </>
                    ) : (
                      <span className="new-price">£{bundle.price}</span>
                    )}
                  </div>

                  {bundle.limited_time_offer_end && (
                    <div className="limited-offer">
                      <FiClock />
                      <CountdownTimer deadline={bundle.limited_time_offer_end} />
                    </div>
                  )}

                  {bundle.bonus_offer && (
                    <div className="bonus-offer">
                      <FiGift />
                      <span>{bundle.bonus_offer}</span>
                    </div>
                  )}

                  <button className="upgrade-btn" onClick={() => handleBundleClick(bundle.id)}>
                    Upgrade & Save
                  </button>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </section>
  );
};

export default ProductUpsell;
