import { axiosInstance } from "../../api/axiosConfig";
import React, { useState } from "react";
import { Form, Button, Container } from "react-bootstrap";
import "./ContactForm.scss";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axiosInstance
      .post("/support/contact-form-submissions/", formData)
      .then((response) => alert("Message sent successfully!"))
      .catch((error) => console.error("Error sending message:", error));
  };

  return (
    <Container className="contact-form mt-4">
      <h2 className="text-center mb-4">Contact Form</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="form-input"
          />
        </Form.Group>
        <Form.Group controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="form-input"
          />
        </Form.Group>
        <Form.Group controlId="formMessage">
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="form-input"
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="w-100 submit-btn">
          Submit
        </Button>
      </Form>
    </Container>
  );
};

export default ContactForm;
