import React, { useState } from "react";
import { searchContent } from "../../api/contentApi";
import "./SearchComponent.scss";
import { Form, Button, InputGroup, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const SearchComponent = ({ onSearch }) => {
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await searchContent(query);
      onSearch(data);
      setLoading(false);
    } catch (error) {
      console.error("Error searching content", error);
      setLoading(false);
    }
  };

  return (
    <div className="search-component">
      <Form onSubmit={handleSearch}>
        <InputGroup>
          <FormControl
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Search for content..."
          />
          <Button type="submit" variant="outline-primary">
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        </InputGroup>
      </Form>
      {loading && <div>Loading...</div>}
    </div>
  );
};

export default SearchComponent;
