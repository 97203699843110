import React, { useState, useEffect, useRef } from "react";
import { FaEye, FaFire } from "react-icons/fa";
import { motion, useAnimation } from "framer-motion"; // ✅ Import for animations
import "./LiveStatsCounter.scss";

const LiveStatsCounter = ({ productId }) => {
  const [visitorCount, setVisitorCount] = useState(0);
  const [salesCount, setSalesCount] = useState(0);
  const wsRef = useRef(null);
  const reconnectRef = useRef(null);
  const visitorControls = useAnimation();
  const salesControls = useAnimation();

  useEffect(() => {
    if (!productId) {
      console.error("❌ Missing productId in LiveStatsCounter");
      return;
    }

    const protocol = window.location.protocol === "https:" ? "wss" : "ws";
    const wsBaseUrl = process.env.REACT_APP_WEBSOCKET_URL;  
    const wsUrl = `${protocol}://${wsBaseUrl}/ws/products/${productId}/`;

    console.log(`🌍 WebSocket URL: ${wsUrl}`);  

    const connectWebSocket = () => {
      console.log(`🔄 Connecting to WebSocket: ${wsUrl}`);
      const websocket = new WebSocket(wsUrl);
      wsRef.current = websocket;

      websocket.onopen = () => {
        console.log(`✅ WebSocket Opened for Product ID: ${productId}`);
        if (reconnectRef.current) clearTimeout(reconnectRef.current);
      };

      websocket.onmessage = (event) => {
        try {
          console.log("📩 Raw WebSocket Message:", event.data);
          const data = JSON.parse(event.data);
          console.log("🔄 Live Data Update:", data);

          if (data.visitor_count !== undefined) {
            visitorControls.start({ scale: [1, 1.2, 1], transition: { duration: 0.3 } });
            setVisitorCount(data.visitor_count);
          }
          if (data.sales_count !== undefined) {
            salesControls.start({ scale: [1, 1.2, 1], transition: { duration: 0.3 } });
            setSalesCount(data.sales_count);
          }
        } catch (error) {
          console.error("❌ Error Parsing WebSocket Message:", error);
        }
      };

      websocket.onclose = () => {
        console.warn("⚠️ WebSocket Disconnected. Reconnecting...");
        reconnectRef.current = setTimeout(connectWebSocket, 3000);
      };
    };

    connectWebSocket();

    return () => {
      if (wsRef.current) wsRef.current.close();
      if (reconnectRef.current) clearTimeout(reconnectRef.current);
    };
  }, [productId, visitorControls, salesControls]);

  return (
    <div className="live-stats-counter">
      <motion.div className="stat-item" animate={visitorControls}>
        <FaEye className="stat-icon" />
        <span className="stat-text">{visitorCount} people viewing now</span>
      </motion.div>
      <motion.div className="stat-item sale-alert" animate={salesControls}>
        <FaFire className="stat-icon" />
        <span className="stat-text">{salesCount} sold today!</span>
      </motion.div>
    </div>
  );
};

export default LiveStatsCounter;
