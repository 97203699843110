import React, { useEffect, useState } from "react";
import { Container, Spinner, Alert, Card } from "react-bootstrap";
import { axiosInstance } from "../../api/axiosConfig";
import "./FAQ.scss";

const NewFAQ = () => {
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeFaq, setActiveFaq] = useState(null);

  useEffect(() => {
    axiosInstance
      .get("/support/faqs/")
      .then((response) => {
        setFaqs(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching FAQs:", error);
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Container className="faq-list text-center">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="faq-list">
        <Alert variant="danger" className="text-center">
          Error fetching FAQs: {error.message}
        </Alert>
      </Container>
    );
  }

  const groupedFaqs = faqs.reduce((groups, faq) => {
    const category = faq.category;
    if (!groups[category]) {
      groups[category] = [];
    }
    groups[category].push(faq);
    return groups;
  }, {});

  const toggleFaq = (id) => {
    setActiveFaq(activeFaq === id ? null : id);
  };

  return (
    <Container className="faq-list mb-4">
      <h2 className="text-center mb-4">Frequently Asked Questions</h2>
      {Object.keys(groupedFaqs).map((category) => (
        <div key={category}>
          <h3>{category}</h3>
          {groupedFaqs[category].map((faq) => (
            <Card
              key={faq.id}
              className={`mb-3 ${activeFaq === faq.id ? "active" : ""}`}
              onClick={() => toggleFaq(faq.id)}
              style={{ cursor: "pointer", transition: "all 0.3s ease-in-out" }}
            >
              <Card.Header>{faq.question}</Card.Header>
              {activeFaq === faq.id && (
                <Card.Body>
                  <p>{faq.answer}</p>
                </Card.Body>
              )}
            </Card>
          ))}
        </div>
      ))}
    </Container>
  );
};

export default NewFAQ;
