import React from "react";
import "./BundleIncludedProducts.scss";

const BundleIncludedProducts = ({ products }) => {
  if (!products || products.length === 0) return null;

  return (
    <div className="bundle-included-products">
      <h2>What’s Included in This Bundle</h2>
      <div className="product-grid">
        {products.map((product) => (
          <div key={product.id} className="included-product-card">
            <img
              src={product.thumbnail_image}
              alt={product.name}
              className="included-product-image"
            />
            <p className="included-product-name">{product.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BundleIncludedProducts;
