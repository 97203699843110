import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faCartPlus,
  faStar,
  faStarHalfAlt,
  faStar as farStar,
} from "@fortawesome/free-solid-svg-icons";
import { addToCart, removeFromCart } from "../../features/cart/cartSlice";
import {
  addToFavourites,
  removeFromFavourites,
} from "../../features/favourites/favouritesSlice";
import "./ProductCard.scss";

const ProductCard = ({ product }) => {
  const dispatch = useDispatch();
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const cartItems = useSelector((state) => state.cart.items);
  const favouriteItems = useSelector((state) => state.favourites.items);

  const isFavourited = favouriteItems.some(item => item.id === product.id);
  const isInCart = cartItems.some(item => item.id === product.id);

  const handleFavouriteClick = () => {
    dispatch(isFavourited ? removeFromFavourites(product) : addToFavourites(product));
  };

  const handleAddToCartClick = () => {
    dispatch(isInCart ? removeFromCart(product) : addToCart(product));
  };

  const renderStars = useCallback((rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 >= 0.5;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    return (
      <div className="star-rating">
        {[...Array(fullStars)].map((_, i) => (
          <FontAwesomeIcon key={`full-${i}`} icon={faStar} className="star-icon" />
        ))}
        {halfStar && <FontAwesomeIcon icon={faStarHalfAlt} className="star-icon" />}
        {[...Array(emptyStars)].map((_, i) => (
          <FontAwesomeIcon key={`empty-${i}`} icon={farStar} className="star-icon" />
        ))}
      </div>
    );
  }, []);

  const renderReviews = () => (
    <div className="product-card__reviews">
      {renderStars(product.average_rating || 0)}
      <span>
        {product.review_count > 0 
          ? `(${product.review_count} review${product.review_count !== 1 ? 's' : ''})`
          : 'No reviews yet'}
      </span>
    </div>
  );

  return (
    <article className="product-card">
      <div className="product-card__header">
        <button
          className={`product-card__action ${isFavourited ? 'product-card__action--favourited' : ''}`}
          onClick={handleFavouriteClick}
          aria-label={isFavourited ? "Remove from favorites" : "Add to favorites"}
        >
          <FontAwesomeIcon icon={faHeart} />
        </button>
        <button
          className={`product-card__action ${isInCart ? 'product-card__action--in-cart' : ''}`}
          onClick={handleAddToCartClick}
          aria-label={isInCart ? "Remove from cart" : "Add to cart"}
        >
          <FontAwesomeIcon icon={faCartPlus} />
        </button>
      </div>

      <div className="product-card__image-wrapper">
        <img
          src={product.thumbnail_image}
          className="product-card__image"
          alt={product.alt_text || product.name}
          loading="lazy"
          data-loaded={imageLoaded}
          onLoad={() => setImageLoaded(true)}
          onError={(e) => {
            e.target.src = "/images/fallback-product.jpg";
            setImageLoaded(true);
          }}
        />
      </div>

      <div className="product-card__content">
        <h3 className="product-card__title">{product.name}</h3>
        <p className="product-card__price">£{Number(product.price).toFixed(2)
        }</p>
        {renderReviews()}
        <Link 
          to={`/product/${product.id}`} 
          className="product-card__details"
          aria-label={`View details for ${product.name}`}
        >
          View Details
        </Link>
      </div>
    </article>
  );
};

ProductCard.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    thumbnail_image: PropTypes.string,
    alt_text: PropTypes.string,
    average_rating: PropTypes.number,
    review_count: PropTypes.number,
  }).isRequired,
};

export default ProductCard;