// src/components/productDetail/ExitIntentPopup.jsx
import React, { useState, useEffect } from "react";
import { FiXCircle, FiDownload } from "react-icons/fi";
import "./ExitIntentPopup.scss";

const ExitIntentPopup = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [email, setEmail] = useState("");
  const [offerClaimed, setOfferClaimed] = useState(false);

  useEffect(() => {
    const handleMouseLeave = (e) => {
      if (e.clientY < 50) {
        setShowPopup(true);
      }
    };

    window.addEventListener("mouseout", handleMouseLeave);
    return () => window.removeEventListener("mouseout", handleMouseLeave);
  }, []);

  const handleClose = () => setShowPopup(false);

  const handleClaimOffer = () => {
    if (!email) {
      alert("📩 Please enter your email to receive your free recipe card.");
      return;
    }

    // TODO: Send email to backend (when API is ready)
    console.log("Email Submitted:", email);
    setOfferClaimed(true);
  };

  if (!showPopup) return null;

  return (
    <div className="exit-intent-popup">
      <div className="popup-content">
        <button className="close-btn" onClick={handleClose}>
          <FiXCircle />
        </button>

        {offerClaimed ? (
          <>
            <h2>🎉 Success! Download Your Free Recipe Card</h2>
            <p>Your exclusive recipe card is ready for download.</p>
            <a href="/static/recipe-card.pdf" download className="download-btn">
              <FiDownload /> Download Now
            </a>
          </>
        ) : (
          <>
            <h2>Wait! Before You Go...</h2>
            <p>
              🎁 Grab a **free premium recipe card** + **10% OFF your first
              order!**
            </p>

            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="email-input"
            />

            <button className="claim-btn" onClick={handleClaimOffer}>
              Claim Free Recipe Card
            </button>

            <p className="no-thanks" onClick={handleClose}>
              No thanks, I want to pay full price.
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default ExitIntentPopup;
