import React, { useState, useEffect } from "react";
import { axiosInstance } from "../../api/axiosConfig";
import { useNavigate } from "react-router-dom";
import "./CustomerReviews.scss";

const CustomerReviews = ({ productId }) => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortOption, setSortOption] = useState("latest");
  const [visibleReviews, setVisibleReviews] = useState(3); // Load 3 at start

  const navigate = useNavigate(); // For redirecting users to dashboard reviews

  useEffect(() => {
    if (!productId) return;

    const fetchReviews = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axiosInstance.get(
          `/products/reviews/?product=${productId}&ordering=${sortOption}`,
        );
        setReviews(response.data);
      } catch (err) {
        console.error("Error fetching reviews:", err);
        setError("Failed to load reviews.");
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, [productId, sortOption]);

  // ⭐ Render Star Ratings
  const renderStars = (rating) => {
    return [...Array(5)].map((_, index) => (
      <span key={index} className={index < rating ? "star filled" : "star"}>
        ★
      </span>
    ));
  };

  return (
    <div className="customer-reviews">
      <h3>Customer Reviews</h3>

      {/* 🏆 Rating Breakdown */}
      <div className="rating-summary">
        <div className="average-rating">
          <span className="big-number">4.8</span> / 5
          <p>(Based on {reviews.length} reviews)</p>
        </div>
        <div className="rating-bars">
          {[5, 4, 3, 2, 1].map((star) => (
            <div key={star} className="rating-row">
              <span>{star} ★</span>
              <div className="bar">
                <div
                  className="fill"
                  style={{
                    width: `${(reviews.filter((r) => r.rating === star).length / reviews.length) * 100}%`,
                  }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* 🔀 Sorting Options */}
      <select
        className="sort-dropdown"
        onChange={(e) => setSortOption(e.target.value)}
      >
        <option value="latest">Most Recent</option>
        <option value="highest">Highest Rated</option>
        <option value="lowest">Lowest Rated</option>
      </select>

      {/* 📝 Reviews List */}
      {loading && <p>Loading reviews...</p>}
      {error && <p className="error">{error}</p>}
      {!loading && !error && reviews.length > 0 ? (
        reviews.slice(0, visibleReviews).map((review) => (
          <div key={review.id} className="review-item">
            <strong>{review.user_display || review.user}</strong>
            <div className="review-rating">{renderStars(review.rating)}</div>
            <p>{review.comment}</p>
          </div>
        ))
      ) : (
        <p>No reviews available.</p>
      )}

      {/* 🔄 Load More Button */}
      {visibleReviews < reviews.length && (
        <button
          onClick={() => setVisibleReviews(visibleReviews + 3)}
          className="load-more"
        >
          Load More Reviews
        </button>
      )}

      {/* ✍️ Redirect to Dashboard for Review Submission
      <div className="review-dashboard-link">
        <p>Want to leave a review?</p>
        <button onClick={() => navigate("/dashboard/reviews")}>
          Write a Review in Your Dashboard
        </button>
      </div> */}
    </div>
  );
};

export default CustomerReviews;
