// src/components/productDetail/ProductBenefits.jsx
import React, { useEffect, useState } from "react";

const ProductBenefits = ({ benefits }) => {
  console.log(
    "🟡 ProductBenefits received:",
    benefits || "❌ No benefits available.",
  );

  if (!benefits) {
    return (
      <div className="product-benefits">
        <h2>Product Benefits</h2>
        <p>Loading benefits...</p>
      </div>
    );
  }

  return (
    <div className="product-benefits">
      <h2>Product Benefits</h2>
      <div dangerouslySetInnerHTML={{ __html: benefits }} />
    </div>
  );
};

export default ProductBenefits;
