import React from "react";
import { FaLock, FaCheckCircle, FaDownload } from "react-icons/fa"; // Import icons
import "./PriceBreakdown.scss";

const PriceBreakdown = ({ price, originalPrice }) => {
  // Convert price values to numbers; if conversion fails, default to 0.
  const currentPrice = Number(price);
  const origPrice = Number(originalPrice);
  const validPrice = !isNaN(currentPrice) ? currentPrice : 0;
  // If originalPrice is not provided or is invalid, fallback to validPrice.
  const validOrigPrice = !isNaN(origPrice) ? origPrice : validPrice;

  // Calculate savings percentage if there's a discount.
  const savings =
    validOrigPrice > validPrice
      ? Math.round(((validOrigPrice - validPrice) / validOrigPrice) * 100)
      : 0;

  return (
    <div className="price-breakdown">
      {validOrigPrice > validPrice && (
        <div className="original-price">
          <span className="strike-through">£{validOrigPrice.toFixed(2)}</span>
        </div>
      )}
      <div className="current-price">
        <span>£{validPrice.toFixed(2)}</span>
      </div>
      {savings > 0 && (
        <div className="savings-highlight" role="alert">
          <span>🔥 You Save {savings}%!</span>
        </div>
      )}
      <div className="trust-badges">
        <span className="badge">
          <FaLock /> Secure Checkout
        </span>
        <span className="badge">
          <FaCheckCircle /> 100% Satisfaction Guarantee
        </span>
        <span className="badge">
          <FaDownload /> Instant Digital Download
        </span>
      </div>
    </div>
  );
};

export default PriceBreakdown;
