import React, { useState, useEffect } from "react";
import { FaShoppingCart, FaFire, FaExclamationCircle } from "react-icons/fa";
import "./StickyBuyNow.scss";

const StickyBuyNow = ({ product, onExpressCheckout }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [scarcityMessage, setScarcityMessage] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (product?.id) {
      fetchScarcity(product.id);
    }
  }, [product]);

  const fetchScarcity = async (productId) => {
    try {
      const response = await fetch(`/api/products/products/${productId}/scarcity/`);
      const data = await response.json();
      if (data.scarcity_message) {
        setScarcityMessage(data.scarcity_message);
      }
    } catch (error) {
      console.error("Error fetching scarcity info:", error);
    }
  };

  const handleBuyNow = () => {
    if (typeof onExpressCheckout === "function") {
      onExpressCheckout(true); // Always pass true for Sticky (save payment)
    } else {
      console.error("onExpressCheckout is not provided or not a function");
      alert("Something went wrong. Please try again later.");
    }
  };

  return (
    <div className={`sticky-buy-now ${isScrolled ? "visible" : ""}`}>
      <button
        onClick={handleBuyNow}
        className="sticky-buy-now__button"
        aria-live="polite"
      >
        <span className="button-content">
          {scarcityMessage ? (
            <>
              <FaExclamationCircle className="icon" aria-hidden="true" />
              <span>{scarcityMessage} – Buy Now</span>
            </>
          ) : (
            <>
              {isScrolled ? (
                <FaShoppingCart className="icon" aria-hidden="true" />
              ) : (
                <FaFire className="icon" aria-hidden="true" />
              )}
              <span>{isScrolled ? "Buy in 1 Click" : "Grab Yours Now"}</span>
            </>
          )}
        </span>
      </button>
    </div>
  );
};

export default StickyBuyNow;
