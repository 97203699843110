import React from "react";
import { Helmet } from "react-helmet";
import HeroSlider from "../components/homePage/PromotionSlider";
import ProductHighlights from "../components/homePage/ProductHighlights";
import Testimonials from "../components/homePage/Testimonials";
import NewsletterSignup from "../components/homePage/NewsletterSignup";
import ContentHubPreview from "../components/homePage/ContentHubPreview";
import "./Homepage.scss";

const Homepage = () => {
  return (
    <div className="homepage">
      {/* SEO Meta Tags & Structured Data */}
      <Helmet>
        <title>Ruoth - Empowering Culinary Creativity</title>
        <meta
          name="description"
          content="Ruoth empowers culinary professionals and enthusiasts with high-quality physical and digital tools that enhance kitchen creativity, precision, and efficiency."
        />
        <meta
          name="keywords"
          content="Ruoth, culinary tools, professional kitchen tools, digital culinary solutions, cake scrapers, sieves, culinary creativity, kitchen innovation, sustainable kitchenware"
        />
        <meta property="og:title" content="Ruoth - Empowering Culinary Creativity" />
        <meta
          property="og:description"
          content="Discover Ruoth’s premium physical and digital tools designed to elevate your culinary craft."
        />
        <meta property="og:image" content="https://www.ruothstore.com/logo192.png" />
        <meta property="og:url" content="https://www.ruothstore.com/" />
        <meta name="twitter:title" content="Ruoth - Empowering Culinary Creativity" />
        <meta
          name="twitter:description"
          content="Explore Ruoth’s innovative kitchen tools and digital solutions for culinary professionals."
        />
        <meta name="twitter:image" content="https://www.ruothstore.com/logo192.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href="https://www.ruothstore.com/" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Ruoth",
            url: "https://www.ruothstore.com/",
            logo: "https://www.ruothstore.com/logo192.png",
            sameAs: [
              "https://www.instagram.com/ruothstore/",
              "https://www.facebook.com/theruothstore",
              "https://www.tiktok.com/@ruoth_store",
              "https://www.amazon.co.uk/stores/RUOTH/page/2D27FE96-504E-40D3-A814-916A5E008D0F",
              "https://www.etsy.com/shop/RuothxStore",
            ],
            description:
              "Ruoth empowers culinary professionals and enthusiasts with high-quality physical and digital tools that enhance kitchen creativity, precision, and efficiency.",
          })}
        </script>
      </Helmet>

      {/* Hero Section */}
      <section className="hero-section section">
        <HeroSlider />
      </section>

      {/* Featured Products */}
      <section className="products-section section">
        <div className="custom-container">
          <h2 className="section-title">Featured Products</h2>
          <ProductHighlights />
        </div>
      </section>

      {/* Content Hub Preview */}
      <section className="content-hub-section section bg-light">
        <div className="custom-container">
          <h2 className="section-title">Latest Articles and Guides</h2>
          <ContentHubPreview />
        </div>
      </section>

      {/* Testimonials */}
      <section className="testimonials-section section">
        <div className="custom-container">
          <h2 className="section-title">What Our Customers Say</h2>
          <Testimonials />
        </div>
      </section>

      {/* Newsletter Signup */}
      <section className="newsletter-section section bg-light">
        <div className="custom-container">
          <h2 className="section-title">Stay Updated</h2>
          <NewsletterSignup />
        </div>
      </section>

      {/* Customer Support */}
      <section className="support-section section bg-light">
        <div className="custom-container">
          <h2 className="section-title">Need Help?</h2>
          <p>
            Visit our customer support section for FAQs and live chat support.
          </p>
          <a href="/customer-support" className="btn primary-btn">
            Go to Customer Support
          </a>
        </div>
      </section>
    </div>
  );
};

export default Homepage;