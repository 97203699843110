import React, { useEffect, useState, useCallback } from "react";
import { axiosInstance } from "../api/axiosConfig";
import { useNavigate } from "react-router-dom";
import "./SubscriptionsPage.scss";

const PriceFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "GBP",
  minimumFractionDigits: 2,
});

const ErrorHandler = (err) => {
  return err?.response?.data?.detail || err.message || "An unexpected error occurred.";
};

const Loader = () => (
  <div style={{ textAlign: "center", padding: "2rem" }}>Loading...</div>
);

const ErrorMessage = ({ message }) => (
  <div style={{ color: "red", padding: "1rem" }}>{message}</div>
);

const ComparisonModal = ({ planIds, onClose }) => (
  <div style={{ background: "#f4f4f4", padding: "1rem", borderRadius: "8px" }}>
    <h2>Compare Plans</h2>
    <p>Plan IDs: {planIds.join(", ")}</p>
    <button onClick={onClose}>Close</button>
  </div>
);

const trackEvent = (name, data) => {
  console.log(`[Track Event] ${name}`, data);
};

// ✅ Main Page
const SubscriptionsPage = () => {
  const [state, setState] = useState({
    plans: {},
    loading: true,
    error: null,
    selectedInterval: "monthly",
    comparisonPlans: [],
    showComparison: false,
  });

  const navigate = useNavigate();

  const fetchPlans = useCallback(async (signal) => {
    try {
      const { data } = await axiosInstance.get("/products/subscription-plans/", { signal });

      const grouped = data.reduce((acc, plan) => {
        if (!plan.active) return acc;
        const key = plan.metadata?.subscription_group || "Other";
        acc[key] = [...(acc[key] || []), plan];
        return acc;
      }, {});

      setState((prev) => ({ ...prev, plans: grouped, loading: false }));
    } catch (err) {
      if (!signal.aborted) {
        setState((prev) => ({
          ...prev,
          error: ErrorHandler(err),
          loading: false,
        }));
      }
    }
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    fetchPlans(controller.signal);
    return () => controller.abort();
  }, [fetchPlans]);

  const handleIntervalChange = (interval) => {
    trackEvent("Interval Toggle", {
      previous: state.selectedInterval,
      new: interval,
    });
    setState((prev) => ({ ...prev, selectedInterval: interval }));
  };

  const handleComparison = (planId) => {
    setState((prev) => ({
      ...prev,
      comparisonPlans: prev.comparisonPlans.includes(planId)
        ? prev.comparisonPlans.filter((id) => id !== planId)
        : [...prev.comparisonPlans, planId],
    }));
  };

  const { loading, error, plans, selectedInterval, comparisonPlans, showComparison } = state;

  if (loading) return <Loader />;
  if (error) return <ErrorMessage message={error} />;

  return (
    <div className="subscriptions-page">
      <h1 className="page-title">Choose Your Membership</h1>

      <div className="subscription-group-columns">
      {Object.entries(plans)
        .sort(([, a], [, b]) => {
          const getMin = (arr) =>
            Math.min(
              ...arr
                .filter((p) => p.active && p.price)
                .map((p) => parseFloat(p.price || "0"))
            );
          return getMin(a) - getMin(b);
        })
        .map(([groupName, planVariants]) => (

          <SubscriptionGroup
            key={groupName}
            groupName={groupName}
            plans={planVariants}
            selectedInterval={selectedInterval}
            onIntervalChange={handleIntervalChange}
            onCompare={handleComparison}
            comparisonList={comparisonPlans}
          />
        ))}
      </div>

      {comparisonPlans.length > 0 && (
        <div className="compare-footer">
          <button
            className="compare-cta"
            onClick={() => setState((prev) => ({ ...prev, showComparison: true }))}
          >
            Compare Plans ({comparisonPlans.length})
          </button>
        </div>
      )}

      {showComparison && (
        <ComparisonModal
          planIds={comparisonPlans}
          onClose={() => setState((prev) => ({ ...prev, showComparison: false }))}
        />
      )}
    </div>
  );
};

// ✅ Subscription Group
const SubscriptionGroup = React.memo(
  ({ groupName, plans, selectedInterval, onIntervalChange, onCompare, comparisonList }) => {
    const filteredPlans = plans
      .filter(
        (plan) =>
          plan.billing_interval === selectedInterval ||
          plan.metadata?.always_visible === "true"
      )
      .sort((a, b) => {
        const priceA = parseFloat(a.price) || 0;
        const priceB = parseFloat(b.price) || 0;
        return priceA - priceB;
      });


    if (filteredPlans.length === 0) return null;

    return (
      <section className="subscription-category">
        <div className="subscription-category-header">
          <h2 className="category-title">{groupName}</h2>
          <div className="interval-toggle">
            <button
              className={selectedInterval === "monthly" ? "active" : ""}
              onClick={() => onIntervalChange("monthly")}
            >
              Monthly
            </button>
            <button
              className={selectedInterval === "yearly" ? "active" : ""}
              onClick={() => onIntervalChange("yearly")}
            >
              Yearly
            </button>
          </div>
        </div>

        <div className="plan-options-grid">
          {filteredPlans.map((plan) => (
            <PlanCard
              key={plan.id}
              plan={plan}
              onCompare={onCompare}
              isComparing={comparisonList.includes(plan.id)}
            />
          ))}
        </div>
      </section>
    );
  }
);

// ✅ Individual Plan Card
const PlanCard = React.memo(({ plan, onCompare, isComparing }) => {
  const navigate = useNavigate();

  const handleSubscribe = () => {
    const isAuthenticated = !!localStorage.getItem("token");
  
    if (!isAuthenticated) {
      navigate("/dashboard"); // Redirect non-authenticated users
    } else {
      alert("🎉 You’ve been added to the early access list! We'll notify you once we're live.");
      trackEvent("Join Waiting List", {
        planId: plan.id,
        interval: plan.billing_interval,
      });
    }
  };
  
  

  return (
    <article className={`plan-card ${isComparing ? "comparing" : ""}`}>
      <div className="plan-header">
        {plan.metadata?.badge && (
          <span className={`badge ${plan.metadata.badge.toLowerCase()}`}>
            {plan.metadata.badge}
          </span>
        )}
        <h3 className="plan-interval">
          {plan.billing_interval.charAt(0).toUpperCase() + plan.billing_interval.slice(1)}
        </h3>
        <p className="plan-price">
          <span className="amount">{PriceFormatter.format(plan.price)}</span>
          <span className="interval">/{plan.billing_interval === "monthly" ? "mo" : "yr"}</span>
        </p>
      </div>

      <div className="plan-body">
        <p className="plan-description">{plan.description}</p>
        <ul className="plan-features">
          {(plan.metadata?.features || "").split(",").map((f, i) => (
            <li key={i}>{f.trim()}</li>
          ))}
        </ul>
      </div>

      <div className="plan-actions">
        <button className="compare-toggle" onClick={() => onCompare(plan.id)}>
          {isComparing ? "Remove from Compare" : "Add to Compare"}
        </button>
        <div className="cta-group">
          {/* <button className="learn-more" onClick={() => navigate(`/subscriptions/${plan.slug}`)}>
            Details
          </button> */}
          <button className="subscribe-now" onClick={handleSubscribe}>
          Join Early Access
          </button>
        </div>
      </div>
    </article>
  );
});

export default SubscriptionsPage;
