// src/utils/websocket.js

export const createWebSocket = (orderId) => {
  const protocol = window.location.protocol === "https:" ? "wss:" : "ws:";
  const host = window.location.host;
  const path = `/ws/orders/${orderId}/`;
  const url = `${protocol}//${host}${path}`;

  return new WebSocket(url);
};
