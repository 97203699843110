import React, { useState, useEffect } from "react";
import "./PostPurchaseEmails.scss";

const PostPurchaseEmails = ({ orderId }) => {
  const [status, setStatus] = useState("pending"); // 'pending', 'comingSoon'

  useEffect(() => {
    if (!orderId) return;

    // Simulating future API integration
    const simulateEmailProcessing = setTimeout(() => {
      setStatus("comingSoon");
    }, 2000); // Fake delay to simulate an action

    return () => clearTimeout(simulateEmailProcessing);
  }, [orderId]);

  return (
    <div className="post-purchase-emails">
      {status === "pending" && <p>Processing your exclusive content...</p>}
      {status === "comingSoon" && (
        <p>📩 Stay tuned! Post-purchase email offers will be coming soon.</p>
      )}
    </div>
  );
};

export default PostPurchaseEmails;
