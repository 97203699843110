import { axiosInstance } from "../../api/axiosConfig";
import { createWebSocket } from "../../utils/websocket";
import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import PaymentStatusNotification from "./PaymentStatusNotification";
import "./PaymentForm.scss";

const PaymentForm = ({ orderId, nextStep, prevStep, setOrder }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [order, setLocalOrder] = useState(null);
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState("");
  const [ws, setWs] = useState(null);

  useEffect(() => {
    axiosInstance
      .get(`/orders/orders/${orderId}/`)
      .then((response) => {
        setLocalOrder(response.data);
        setOrder(response.data);
      })
      .catch((error) => console.error("Error fetching order:", error));
  }, [orderId, setOrder]);

  useEffect(() => {
    const socket = createWebSocket(orderId);
    setWs(socket);

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.message === "Payment succeeded") {
        setStatus("succeeded");
        nextStep();
      } else if (data.message === "Payment failed") {
        setStatus("failed");
        setMessage(data.message || "Payment failed. Please try again.");
      }
    };

    socket.onclose = () => console.log("WebSocket connection closed");

    return () => socket.close();
  }, [orderId, nextStep]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements || !order) {
      setStatus("failed");
      setMessage("Stripe.js has not loaded yet or order is not created.");
      return;
    }

    setStatus("processing");

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (error) {
      setStatus("failed");
      setMessage(error.message);
      return;
    }

    try {
      const response = await axiosInstance.post(
        `/orders/${orderId}/process_payment/`,
        { paymentMethod: paymentMethod.id },
      );

      if (response.data.status === "requires_action") {
        setStatus("requires_action");
        const { error: confirmError } = await stripe.confirmCardPayment(
          response.data.payment_intent_client_secret,
        );

        if (confirmError) {
          setStatus("failed");
          setMessage(confirmError.message);
        } else {
          setStatus("succeeded");
          nextStep();
        }
      } else if (response.data.status === "Payment successful") {
        setStatus("succeeded");
        nextStep();
      } else {
        setStatus("failed");
        setMessage(response.data.error || "Payment failed. Please try again.");
      }
    } catch (error) {
      setStatus("failed");
      setMessage(
        error.response?.data?.error || "An error occurred during payment.",
      );
    }
  };

  return (
    <form onSubmit={handleSubmit} className="payment-form">
      <PaymentStatusNotification
        status={status}
        message={message}
        retryPayment={handleSubmit}
      />
      <CardElement className="card-element" />
      <button
        type="submit"
        disabled={!stripe || !elements || !order}
        className="pay-button"
      >
        Pay
      </button>
      <button type="button" onClick={prevStep} className="back-button">
        Previous Step
      </button>
    </form>
  );
};

export default PaymentForm;
