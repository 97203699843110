import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet"; // Import React Helmet
import { Link } from "react-router-dom";
import { removeFromFavourites } from "../features/favourites/favouritesSlice";
import { addToCart } from "../features/cart/cartSlice";
import "./Favourites.scss";

const Favourites = () => {
  const favouriteItems = useSelector((state) => state.favourites.items);
  const cartItems = useSelector((state) => state.cart.items);
  const dispatch = useDispatch();

  const handleRemoveFromFavourites = (product) => {
    dispatch(removeFromFavourites(product));
  };

  const handleAddToCart = (product) => {
    if (!cartItems.some((item) => item.id === product.id)) {
      dispatch(addToCart(product));
    }
  };

  return (
    <div className="favourites-page">
      {/* Meta Tags for SEO */}
      <Helmet>
        <title>Your Favourites | Ruoth</title>
        <meta
          name="description"
          content="View and manage your favourite products on Ruoth."
        />
        <meta
          name="keywords"
          content="Ruoth favourites, favourite products, wishlist, shopping"
        />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Your Favourites | Ruoth" />
        <meta
          property="og:description"
          content="Check out your favourite products on Ruoth."
        />
        <meta
          property="og:image"
          content="https://www.ruothstore.com/assets/images/favourites-banner.jpg"
        />
        <meta
          property="og:url"
          content="https://www.ruothstore.com/favourites"
        />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:title" content="Your Favourites | Ruoth" />
        <meta
          name="twitter:description"
          content="View your favourite products on Ruoth."
        />
        <meta
          name="twitter:image"
          content="https://www.ruothstore.com/assets/images/favourites-banner.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Canonical Link */}
        <link rel="canonical" href="https://www.ruothstore.com/favourites" />
      </Helmet>

      <h1>Your Favourites</h1>
      {favouriteItems.length === 0 ? (
        <p>You have no favourite items</p>
      ) : (
        <div className="favourite-items">
          {favouriteItems.map((product) => (
            <div key={product.id} className="favourite-item">
              <Link to={`/product/${product.id}`}>
                <img src={product.thumbnail_image} alt={product.name} />
              </Link>
              <div className="favourite-item-info">
                <Link to={`/product/${product.id}`}>
                  <h2>{product.name}</h2>
                </Link>
                <p>Price: £{product.price}</p>
                <button onClick={() => handleRemoveFromFavourites(product)}>
                  Remove
                </button>
                {!cartItems.some((item) => item.id === product.id) && (
                  <button onClick={() => handleAddToCart(product)}>
                    Add to Cart
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Favourites;
