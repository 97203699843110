import React from "react";
import NavBar from "./homePage/NavBar";
import Footer from "./homePage/Footer";
import AnalyticsTracker from "./AnalyticsTracker"; // Import the AnalyticsTracker component

const Layout = ({ children }) => {
  return (
    <div
      style={{
        minHeight: "100vh",        // Ensures the container is at least as tall as the viewport
        display: "flex",           // Uses flexbox for layout
        flexDirection: "column",   // Stacks child components vertically
      }}
    >
      <AnalyticsTracker />
      <NavBar />
      <main style={{ flexGrow: 1 }}>
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;