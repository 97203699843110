import React, { useState, useEffect } from "react";
import { axiosInstance } from "../../api/axiosConfig";
import { useParams } from "react-router-dom";
import "./ProductSpecifications.scss";

const ProductSpecifications = () => {
  const { id } = useParams();
  const [specifications, setSpecifications] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSpecifications = async () => {
      try {
        const response = await axiosInstance.get(`/products/products/${id}/`);
        const product = response.data;

        // Build specifications object dynamically
        const specs = {
          "Product Type":
            product.product_type.charAt(0).toUpperCase() +
            product.product_type.slice(1),
          Category: product.category ? product.category.name : "N/A",
          Brand: product.brand || "N/A",
          Weight: product.weight ? `${product.weight} kg` : "N/A",
          Dimensions: product.dimensions || "N/A",
          Color: product.color || "N/A",
          Material: product.material || "N/A",
          "Eco-Friendly": product.eco_friendly ? "Yes" : "No",
          Stock:
            product.stock !== null ? `${product.stock} units available` : "N/A",
        };

        setSpecifications(specs);
      } catch (error) {
        console.error("Error fetching product specifications:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSpecifications();
  }, [id]);

  if (loading) return <p>Loading specifications...</p>;

  return (
    <div className="product-specifications">
      <h3>Specifications</h3>
      {specifications ? (
        <table>
          <tbody>
            {Object.entries(specifications).map(([key, value], index) => (
              <tr key={index}>
                <td className="spec-key">{key}</td>
                <td className="spec-value">{value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No specifications available for this product.</p>
      )}
    </div>
  );
};

export default ProductSpecifications;
