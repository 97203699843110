import React from "react";
import { FiCheckCircle, FiXCircle, FiAlertTriangle } from "react-icons/fi";
import "./ProductLicensing.scss";

const fallbackLicenses = [
  {
    license_type: "Personal Use",
    description: "Use for personal projects. Redistribution and resale are not allowed.",
    allowed: true,
    requires_attribution: false,
  },
  {
    license_type: "Commercial Use",
    description: "Commercial use allowed with creator attribution required.",
    allowed: true,
    requires_attribution: true,
  },
  {
    license_type: "Extended License",
    description: "Unlimited commercial use with no attribution.",
    allowed: false,
    requires_attribution: false,
  },
];

const ProductLicensing = ({ licenses = [] }) => {
  const displayLicenses = licenses.length ? licenses : fallbackLicenses;

  return (
    <section className="product-licensing" aria-label="Product licensing information">
      <h3 className="licensing-title">Licensing Details</h3>
      <div className="licensing-grid">
        {displayLicenses.map((license, index) => {
          const isAllowed = license.allowed;
          const isConditional = isAllowed && license.requires_attribution;
          const isDisallowed = !isAllowed;

          return (
            <div
              key={index}
              className={`license-card ${
                isDisallowed
                  ? "not-allowed"
                  : isConditional
                    ? "conditional"
                    : "allowed"
              }`}
              role="listitem"
            >
              <div className="license-icon-container">
                {isDisallowed ? (
                  <FiXCircle className="license-icon" aria-label="Not allowed" />
                ) : isConditional ? (
                  <FiAlertTriangle className="license-icon" aria-label="Requires attribution" />
                ) : (
                  <FiCheckCircle className="license-icon" aria-label="Allowed" />
                )}
              </div>
              <div className="license-content">
                <h4 className="license-type">{license.license_type}</h4>
                <p className="license-description">{license.description}</p>
                {isConditional && (
                  <div className="license-notice">
                    <FiAlertTriangle className="notice-icon" />
                    <span>Requires attribution</span>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default ProductLicensing;
