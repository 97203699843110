import React from "react";
import "./Pagination.scss";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePrevious = () => currentPage > 1 && onPageChange(currentPage - 1);
  const handleNext = () => currentPage < totalPages && onPageChange(currentPage + 1);

  return (
    <div className="pagination">
      <div className="pagination__container">
        <button
          className="pagination__button pagination__button--prev"
          onClick={handlePrevious}
          disabled={currentPage === 1}
        >
          <span className="pagination__button-text">Previous</span>
        </button>
        
        <span className="pagination__counter">
          Page <strong>{currentPage}</strong> of <strong>{totalPages}</strong>
        </span>
        
        <button
          className="pagination__button pagination__button--next"
          onClick={handleNext}
          disabled={currentPage === totalPages}
        >
          <span className="pagination__button-text">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Pagination;