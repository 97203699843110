import React from "react";

const ProductFeatures = ({ features }) => {
  console.log(
    "🟡 ProductFeatures received:",
    features || "❌ No features available.",
  );

  if (!features) {
    return (
      <div className="product-features">
        <h2>Product Features</h2>
        <p>Loading features...</p>
      </div>
    );
  }

  return (
    <div className="product-features">
      <h2>Product Features</h2>
      <div dangerouslySetInnerHTML={{ __html: features }} />
    </div>
  );
};

export default ProductFeatures;
