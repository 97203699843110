import React from "react";
import { useNavigate } from "react-router-dom";
import { FiZap, FiStar, FiArrowRight } from "react-icons/fi"; // ✅ Replaced missing icons
import "./SubscriptionUpsell.scss";

const SubscriptionUpsell = () => {
  const navigate = useNavigate();

  const handleUpgrade = () => {
    navigate("/subscriptions");
  };

  return (
    <section
      className="subscription-upsell"
      aria-label="Premium subscription offer"
    >
      <div className="upsell-header">
        <FiZap className="header-icon" aria-hidden="true" />{" "}
        {/* ⚡ Replaces FiRocket */}
        <h3>Unlock the Ultimate Experience!</h3>
      </div>
      <p className="upsell-description">
        <FiStar className="inline-icon" /> {/* ⭐ Replaces FiSparkles */}
        Get unlimited access to exclusive designs, members-only discounts, and
        VIP perks.
      </p>
      <button onClick={handleUpgrade} className="upgrade-button">
        <span>Join the Ruoth Subscription Club</span>
        <FiArrowRight className="button-icon" />
      </button>
    </section>
  );
};

export default SubscriptionUpsell;
