import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../api/axiosConfig";
import { jwtDecode } from "jwt-decode"; // To handle token decoding

// Fetch user details
export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async (_, { getState, rejectWithValue }) => {
    const state = getState();
    const token = state.user.token;

    if (!token) {
      return rejectWithValue("No token found");
    }

    // Check if the token has expired
    const isTokenExpired = (token) => {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convert to seconds
      return decodedToken.exp < currentTime;
    };

    if (isTokenExpired(token)) {
      localStorage.removeItem("token"); // Remove expired token from storage
      return rejectWithValue("Token expired");
    }

    try {
      const response = await axiosInstance.get("/accounts/user/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(
        "Fetch user error:",
        error.response ? error.response.data : error.message,
      );
      return rejectWithValue(
        error.response ? error.response.data : error.message,
      );
    }
  },
);

// Update user details
export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (userData, { getState, rejectWithValue }) => {
    const state = getState();
    const token = state.user.token;

    if (!token) {
      return rejectWithValue("No token found");
    }

    try {
      const response = await axiosInstance.put(
        `/accounts/users/${userData.id}/`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      console.error(
        "Update user error:",
        error.response ? error.response.data : error.message,
      );
      return rejectWithValue(
        error.response ? error.response.data : error.message,
      );
    }
  },
);

// Register user
export const registerUser = createAsyncThunk(
  "user/registerUser",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        "/accounts/register/",
        userData,
      );
      localStorage.setItem("token", response.data.access); // Store token in local storage
      return response.data;
    } catch (error) {
      console.error(
        "Register user error:",
        error.response ? error.response.data : error.message,
      );
      return rejectWithValue(
        error.response ? error.response.data : error.message,
      );
    }
  },
);

// Login user
export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (userData, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/accounts/login/", userData);

      // Store token and userId in local storage
      localStorage.setItem("token", response.data.access); // Store token

      // Check if the user object or userId exists in the response
      const userId = response.data.user?.id; // Extract userId from the response
      if (userId) {
        localStorage.setItem("userId", userId); // Store userId in localStorage
      } else {
        console.error("User ID not found in the login response.");
      }

      await dispatch(fetchUser()); // Fetch user details after login
      return response.data;
    } catch (error) {
      console.error(
        "Login user error:",
        error.response ? error.response.data : error.message,
      );
      return rejectWithValue(
        error.response ? error.response.data : error.message,
      );
    }
  },
);

// Logout user
export const logoutUser = createAsyncThunk(
  "user/logoutUser",
  async (_, { dispatch }) => {
    try {
      await axiosInstance.post("/accounts/logout/");
    } catch (error) {
      console.error("Logout error:", error);
    }
    localStorage.removeItem("token"); // Clear the token from local storage
    dispatch(clearUserData()); // Clear the user data from the state
  },
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    userInfo: null,
    status: "idle",
    error: null,
    token: localStorage.getItem("token"), // Retrieve token from local storage on load
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    clearUserData: (state) => {
      state.userInfo = null;
      state.token = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userInfo = action.payload;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(updateUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userInfo = action.payload;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(registerUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userInfo = action.payload.user;
        state.token = action.payload.access;
        state.error = null;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(loginUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userInfo = action.payload.user;
        state.token = action.payload.access;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.status = "idle";
        state.userInfo = null;
        state.token = null;
      });
  },
});

export const { clearError, clearUserData } = userSlice.actions;

export default userSlice.reducer;
