import React from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { FiStar } from "react-icons/fi";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./RelatedProducts.scss";

const RelatedProducts = ({ products }) => {
  const navigate = useNavigate();

  const handleProductClick = (productId) => {
    navigate(`/product/${productId}`);
  };

  return (
    <section className="related-products">
      <h2>Related Products</h2>

      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={20}
        slidesPerView={1.2}
        breakpoints={{
          600: { slidesPerView: 2 },
          900: { slidesPerView: 3 },
          1200: { slidesPerView: 4 },
        }}
        pagination={{ clickable: true }}
        navigation
        watchOverflow
        className="related-swiper"
      >
        {products.map((product) => (
          <SwiperSlide key={product.id}>
            <div
              className="related-product-item"
              onClick={() => handleProductClick(product.id)}
            >
              <img
                src={product.thumbnail_image}
                alt={product.name}
                loading="lazy"
              />
              <h3>{product.name}</h3>

              <div className="pricing">
                {product.discount_price ? (
                  <>
                    <span className="old-price">£{product.price}</span>
                    <span className="new-price">£{product.discount_price}</span>
                  </>
                ) : (
                  <span className="new-price">£{product.price}</span>
                )}
              </div>

              <div className="ratings">
                {[...Array(5)].map((_, i) => (
                  <FiStar
                    key={i}
                    className={i < product.average_rating ? "filled" : "empty"}
                  />
                ))}
                <span>({product.review_count})</span>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default RelatedProducts;
