import React, { use, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../../features/user/userSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingCart,
  faHeart,
  faCashRegister,
  faSearch,
  faBars,
  faTimes,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/logo.svg";
import "./NavBar.scss";

const NavBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.user) || {};
  const cart = useSelector((state) => state.cart.items);
  const favourites = useSelector((state) => state.favourites.items);

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  useEffect(() => {
    let lastScrollTop = 0;
    const navbar = document.querySelector(".custom-navbar");

    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > lastScrollTop) {
        navbar.classList.remove("scroll-up");
        navbar.classList.add("scroll-down");
      } else {
        navbar.classList.remove("scroll-down");
        navbar.classList.add("scroll-up");
      }

      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleLogout = () => {
    dispatch(logoutUser()).then(() => {
      navigate("/login");
    });
    setMenuOpen(false);
  };

  return (
    <header className="custom-navbar">
      {/* Top Tier: Always Visible */}
      <div className="navbar-top">
        <div className="brand-logo">
          <Link to="/">
            <img src={logo} alt="RuothStore" />
          </Link>
        </div>
        <div className="utility-icons">
          <Link to="/cart" className="icon-link" onClick={() => setMenuOpen(false)}>
            <FontAwesomeIcon icon={faShoppingCart} />
            <span className="badge">{cart.length}</span>
          </Link>
          <Link to="/favourites" className="icon-link" onClick={() => setMenuOpen(false)}>
            <FontAwesomeIcon icon={faHeart} />
            <span className="badge">{favourites.length}</span>
          </Link>
          <Link to="/checkout" className="icon-link" onClick={() => setMenuOpen(false)}>
            <FontAwesomeIcon icon={faCashRegister} />
          </Link>
          {/* Mobile menu toggle */}
          <button className="mobile-menu-button" onClick={toggleMenu}>
            <FontAwesomeIcon icon={menuOpen ? faTimes : faBars} />
          </button>
        </div>
      </div>

      {/* Revised Desktop Layout */}
      <div className="desktop-only">
        <div className="navbar-middle">
          <div className="user-auth">
            {userInfo ? (
              <div className="logged-in">
                <FontAwesomeIcon icon={faUser} />
                <span>Hi, {userInfo.username}</span>
                <div className="auth-dropdown">
                  <Link to="/dashboard">Dashboard</Link>
                  <button onClick={handleLogout}>Logout</button>
                </div>
              </div>
            ) : (
              <div className="auth-links">
                <Link to="/login">Login</Link>
                <span aria-hidden="true">/</span>
                <Link to="/register">Register</Link>
              </div>
            )}
          </div>

          <div className="search-container">
            <form className="search-form" onSubmit={(e) => e.preventDefault()}>
              <input 
                type="search"
                placeholder="Search Products..."
                className="search-input"
                aria-label="Product search"
              />
              <button type="submit" className="search-button">
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </form>
          </div>

          <nav className="navbar-bottom">
            <ul className="nav-links">
              {['Catalogue', 'Content Hub', 'Customer Support'].map((link) => (
                <li key={link}>
                  <Link to={`/${link.toLowerCase().replace(' ', '-')}`}>
                    {link}
                  </Link>
                </li>
              ))}
              {userInfo && <li><Link to="/dashboard">Dashboard</Link></li>}
            </ul>
          </nav>
        </div>
      </div>

      {/* Mobile View: Off-Canvas Side Menu */}
      <div className={`side-menu ${menuOpen ? "open" : ""}`}>
        <button className="close-btn" onClick={toggleMenu}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <div className="user-auth-mobile">
          {userInfo ? (
            <div className="logged-in">
              <span>Welcome, {userInfo.username}</span>
              <Link to="/dashboard" onClick={() => setMenuOpen(false)}>
                Dashboard
              </Link>
              <button onClick={handleLogout}>Logout</button>
            </div>
          ) : (
            <div className="auth-links">
              <Link to="/login" onClick={() => setMenuOpen(false)}>
                Login
              </Link>
              <Link to="/register" onClick={() => setMenuOpen(false)}>
                Register
              </Link>
            </div>
          )}
        </div>
        <div className="search-container-mobile">
          <form className="search-form">
            <input
              type="text"
              placeholder="Search Products"
              className="search-input"
            />
            <button
              type="submit"
              className="search-button"
              onClick={() => setMenuOpen(false)}
            >
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </form>
        </div>
        <ul className="nav-links-mobile">
          <li>
            <Link to="/catalogue" onClick={() => setMenuOpen(false)}>
              Catalogue
            </Link>
          </li>
          <li>
            <Link to="/content-hub" onClick={() => setMenuOpen(false)}>
              Content Hub
            </Link>
          </li>
          <li>
            <Link to="/customer-support" onClick={() => setMenuOpen(false)}>
              Customer Support
            </Link>
          </li>
          {userInfo && (
            <li>
              <Link to="/dashboard" onClick={() => setMenuOpen(false)}>
                Dashboard
              </Link>
            </li>
          )}
        </ul>
      </div>
    </header>
  );
};

export default NavBar;