import React from "react";
import { FaFireAlt } from "react-icons/fa";
import "./BundleHighlights.scss";

const BundleHighlights = ({ bundle }) => {
  if (!bundle) return null;

  return (
    <div className="bundle-highlights">
      <h2>Why This Bundle Is Special</h2>
      <ul>
        <li>
          <FaFireAlt className="icon" />
          Join 100s of creators already using this bundle
        </li>
        <li>
          <FaFireAlt className="icon" />
          Showcase your recipe collection in premium style
        </li>
        <li>
          <FaFireAlt className="icon" />
          Instantly boost the value of your kitchen printables
        </li>
      </ul>
    </div>
  );
};

export default BundleHighlights;
