// src/components/checkoutProcess/PaymentStatusNotification.js

import React from "react";
import PropTypes from "prop-types";
import "./PaymentStatusNotification.scss";

const PaymentStatusNotification = ({ status, message, retryPayment }) => {
  const getMessage = () => {
    switch (status) {
      case "processing":
        return "Your payment is being processed...";
      case "requires_action":
        return "Please complete the authentication steps to finalize your payment.";
      case "succeeded":
        return "Payment successful! Thank you for your purchase.";
      case "failed":
        return (
          <>
            <p>
              Payment failed.{" "}
              {message || "Please try again or use a different payment method."}
            </p>
            {retryPayment && (
              <button onClick={retryPayment} className="retry-button">
                Retry Payment
              </button>
            )}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className={`payment-status-notification ${status}`}>
      {getMessage()}
    </div>
  );
};

PaymentStatusNotification.propTypes = {
  status: PropTypes.string.isRequired, // The current status of the payment
  message: PropTypes.string, // Optional error or detailed message
  retryPayment: PropTypes.func, // Optional retry function for failed payments
};

export default PaymentStatusNotification;
