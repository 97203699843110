import { axiosInstance } from "../api/axiosConfig";
import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
// Removed Helmet as SEO is handled by SeoMetaTags

// Redux imports for cart and favourites
import { useDispatch, useSelector } from "react-redux";
import { addToCart, removeFromCart } from "../features/cart/cartSlice";
import {
  addToFavourites,
  removeFromFavourites,
} from "../features/favourites/favouritesSlice";

// New modular Product Detail Components
import ProductImageGallery from "../components/productDetail/ProductImageGallery";
import RelatedProducts from "../components/productDetail/RelatedProducts";
import CustomerReviews from "../components/productDetail/CustomerReviews";
import ProductDescription from "../components/productDetail/ProductDescription";
import ProductFeatures from "../components/productDetail/ProductFeatures";
import ProductAdvantages from "../components/productDetail/ProductAdvantages";
import ProductBenefits from "../components/productDetail/ProductBenefits";
import ProductFAQ from "../components/productDetail/ProductFAQ";
import StickyBuyNow from "../components/productDetail/StickyBuyNow";
import LiveStatsCounter from "../components/productDetail/LiveStatsCounter";
import CountdownTimer from "../components/productDetail/CountdownTimer";
import ProductUpsell from "../components/productDetail/ProductUpsell";
import SeoMetaTags from "../components/productDetail/SeoMetaTags";
import ExitIntentPopup from "../components/productDetail/ExitIntentPopup";
import ProductSpecifications from "../components/productDetail/ProductSpecifications";
import ProductHighlights from "../components/productDetail/ProductHighlights";
import ProductTicker from "../components/productDetail/ProductTicker";
import ProductLicensing from "../components/productDetail/ProductLicensing";
import SubscriptionUpsell from "../components/productDetail/SubscriptionUpsell";
import PostPurchaseEmails from "../components/productDetail/PostPurchaseEmails";
import ProductDetailsCarousel from "../components/productDetail/ProductDetailsCarousel";

// New components for enhanced conversions
import CustomerPhotos from "../components/productDetail/CustomerPhotos";
import PriceBreakdown from "../components/productDetail/PriceBreakdown";
import ExpressCheckout from "../components/productDetail/ExpressCheckout";

// UI and styling imports
import { Nav, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faStarHalfAlt,
  faStar as farStar,
} from "@fortawesome/free-solid-svg-icons";
import sanitizeHtml from "sanitize-html";
import "./ProductDetailPage.scss";

const ProductDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reviewsTabRef = useRef(null);

  const { userInfo, token } = useSelector((state) => state.user);
  const cartItems = useSelector((state) => state.cart.items);
  const favouriteItems = useSelector((state) => state.favourites.items);

  const [product, setProduct] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [isFavourited, setIsFavourited] = useState(false);
  const [isInCart, setIsInCart] = useState(false);
  const [loading, setLoading] = useState(true);

  // Fetch product and related products
  useEffect(() => {
    const fetchProductDetails = async () => {
      setLoading(true);
      try {
        const productResponse = await axiosInstance.get(
          `/products/products/${id}/`,
        );
        // console.log('✅ Product Data from Backend:', productResponse.data); // ✅ Check if product is fetched
        setProduct(productResponse.data);

        const relatedResponse = await axiosInstance.get(
          `/products/products/${id}/related/`,
        );
        setRelatedProducts(relatedResponse.data);

        window.scrollTo({ top: 0, behavior: "smooth" });
      } catch (error) {
        console.error("Error fetching product details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [id]);

  // Update cart and favourite status based on Redux store
  useEffect(() => {
    setIsFavourited(favouriteItems.some((item) => item.id === parseInt(id)));
    setIsInCart(cartItems.some((item) => item.id === parseInt(id)));
  }, [favouriteItems, cartItems, id]);

  const handleAddToCart = () => {
    if (isInCart) {
      dispatch(removeFromCart(product));
    } else {
      dispatch(addToCart(product));
    }
    setIsInCart(!isInCart);
  };

  const handleAddToFavourites = () => {
    if (isFavourited) {
      dispatch(removeFromFavourites(product));
    } else {
      dispatch(addToFavourites(product));
    }
    setIsFavourited(!isFavourited);
  };

  const handleRelatedProductClick = (productId) => {
    if (parseInt(productId) !== parseInt(id)) {
      navigate(`/product/${productId}`);
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  // Express checkout handler (replace with real integration)
  const handleExpressCheckout = async (savePayment) => {
    if (!userInfo || !token) {
      navigate(
        `/login?redirect=${encodeURIComponent(window.location.pathname)}`,
      );
      return;
    }

    try {
      setLoading(true); // ✅ Prevents multiple clicks during checkout

      // console.log("🟢 Express Checkout Initiated for Product:", product.id);
      // console.log("🛒 Save Payment Preference:", savePayment);

      const response = await axiosInstance.post("/orders/express_checkout/", {
        productId: product.id,
        savePayment,
      });

      console.log("✅ Express Checkout API Response:", response.data);

      if (response.data.success) {
        if (response.data.order_id) {
          // console.log("🔄 Redirecting to Order Confirmation:", response.data.order_id);
          navigate(`/order-confirmation/${response.data.order_id}`);
        } else if (response.data.checkoutUrl) {
          // console.log("🔗 Redirecting to Stripe Checkout:", response.data.checkoutUrl);
          window.location.href = response.data.checkoutUrl;
        } else {
          alert(
            "⚠️ Unexpected response from Express Checkout. Please try again.",
          );
        }
      } else {
        alert(
          "⚠️ Express Checkout failed. Please check your details and try again.",
        );
      }
    } catch (error) {
      console.error("❌ Express Checkout Error:", error);

      if (error.response) {
        console.error("📡 API Response Data:", error.response.data);
        alert(
          `⚠️ Checkout Failed: ${error.response.data.message || "Unexpected error."}`,
        );
      } else {
        alert("⚠️ Network error. Please check your connection and try again.");
      }
    } finally {
      setLoading(false); // ✅ Re-enable button after API response
    }
  };

  // Render star ratings
  const renderStars = (rating) => {
    // Validate rating value
    if (typeof rating !== "number" || isNaN(rating) || rating < 0) {
      rating = 0;
    }
    // Clamp rating to a maximum of 5
    rating = Math.min(rating, 5);

    const fullStars = Math.floor(rating);
    const hasHalfStar = rating - fullStars >= 0.5;
    const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

    return (
      <>
        {Array.from({ length: fullStars }, (_, i) => (
          <FontAwesomeIcon
            key={`full-${i}`}
            icon={faStar}
            className="star-icon"
          />
        ))}
        {hasHalfStar && (
          <FontAwesomeIcon icon={faStarHalfAlt} className="star-icon" />
        )}
        {Array.from({ length: emptyStars }, (_, i) => (
          <FontAwesomeIcon
            key={`empty-${i}`}
            icon={farStar}
            className="star-icon"
          />
        ))}
      </>
    );
  };

  // Sanitize the product description
  const sanitizedDescription = product
    ? sanitizeHtml(product.description, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat([
          "img",
          "h1",
          "h2",
          "h3",
          "p",
          "ul",
          "ol",
          "li",
          "strong",
          "em",
          "a",
          "table",
          "tr",
          "th",
          "td",
          "thead",
          "tbody",
          "iframe",
        ]),
        allowedAttributes: {
          ...sanitizeHtml.defaults.allowedAttributes,
          img: ["src", "alt", "width", "height", "style"],
          a: ["href", "target"],
          iframe: [
            "src",
            "width",
            "height",
            "frameborder",
            "allow",
            "allowfullscreen",
          ],
          "*": ["style", "class", "id"],
        },
      })
    : "";

  // Scroll to reviews section
  const scrollToReviews = () => {
    if (reviewsTabRef.current) {
      reviewsTabRef.current.click(); // 🔥 Switch to the "Reviews" tab
      reviewsTabRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (loading) return <div>Loading product details...</div>;
  if (!product) return <div>Product not found.</div>;
  // console.log('🟢 Passing description to ProductDescription:', product?.description);


  return (
    <div className="product-detail-page">
      {/* ✅ SEO Meta Tags */}
      <SeoMetaTags product={product} />
      {/* 🔥 ABOVE-THE-FOLD (High-Impact Zone) */}
      <div className="above-the-fold">
        <div className="left-column">
          <ProductImageGallery images={product.gallery_images} />
        </div>

        <div className="right-column">
          <h1>{product.name}</h1>
          <PriceBreakdown
              price={product.discount_price || product.price}
              originalPrice={product.discount_price ? product.price : null}
          />

          {/* 🔥 Countdown Timer (Above-the-Fold) for Immediate Urgency */}
            {product && product.id ? (
              <CountdownTimer productId={product.id} />
            ) : (
              <div>Loading countdown...</div>
            )}

          <LiveStatsCounter productId={product.id} />

          {/* ✅ Reviews Summary (Maintained for quick trust-building) */}
          <div 
            className="reviews-summary" 
            onClick={scrollToReviews} 
            style={{ cursor: "pointer", textDecoration: "underline" }}
          >
            {renderStars(product?.average_rating || 0)} ({product?.review_count || 0} reviews)
          </div>

          {/* ✅ Add to Cart & Favourites */}
          <div className="product-buttons">
            <button onClick={handleAddToCart}>
              {isInCart ? "Remove from Cart" : "Add to Cart"}
            </button>
            <button onClick={handleAddToFavourites}>
              {isFavourited ? "Remove from Favourites" : "Add to Favourites"}
            </button>
          </div>

          {/* ✅ Express Checkout & Sticky Buy Now */}
          <ExpressCheckout
            product={product}
            onExpressCheckout={handleExpressCheckout}
          />
          <StickyBuyNow 
            product={product}
            onExpressCheckout={handleExpressCheckout} />
        </div>
      </div>
      {/* 🔻 SMART URGENCY & TRUST ZONE */}
        <div className="trust-zone">
          {product && product.id ? (
            <CountdownTimer productId={product.id} />
          ) : (
            <div>Loading countdown...</div>
          )}
          <ProductTicker productType={product?.product_type === "digital" ? "digital" : "physical"} />

        </div>
      {/* 📝 PRODUCT DESCRIPTION & BENEFITS */}
      {/* 📝 PRODUCT DETAILS CAROUSEL */}
      <div className="product-details-section">
        <ProductDetailsCarousel product={product} />
      </div>
      {/* 📜 SPECS & REVIEWS (WITH TABS) */}
      <div className="specs-and-reviews-section">
      <Tab.Container defaultActiveKey="reviews">
        <Nav variant="tabs" className="product-tabs custom-tabs">
          <Nav.Item className="custom-nav-item">
            <Nav.Link eventKey="reviews" ref={reviewsTabRef} className="custom-nav-link">
              Reviews
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="custom-nav-item">
            <Nav.Link eventKey="specs" className="custom-nav-link">
              Specifications
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content className="custom-tab-content">
          <Tab.Pane eventKey="reviews" className="custom-tab-pane">
            <CustomerReviews productId={product.id} />
          </Tab.Pane>
          <Tab.Pane eventKey="specs" className="custom-tab-pane">
            <ProductSpecifications specs={product.specifications} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
      {/* 🛒 SOCIAL PROOF & USER-GENERATED CONTENT */}
      <div className="social-proof-section">
        <CustomerPhotos productId={product.id} />
      </div>
      {/* 📜 FAQs & LICENSING */}
      <div className="faqs-licensing">
        <div className="faqs-column">
          <ProductFAQ faqs={product.faqs} />
        </div>
        {product.licenses?.length > 0 && (
          <div className="licensing-column">
            <ProductLicensing licenses={product.licenses} />
          </div>
        )}
      </div>
      {/* 🎁 ADDITIONAL OFFERS & UPSELLS (NATURALLY INTEGRATED) */}
      <div className="upsells-section">
          <div className="upsell-carousel">
            <ProductUpsell />
          </div>
          <div className="upsell-blocks">
            <SubscriptionUpsell />
          </div>
          <div className="related-carousel">
            <RelatedProducts products={relatedProducts} />
          </div>
        </div>
      {/* 🚀 SMART EXIT & POST-PURCHASE STRATEGY */}
      <ExitIntentPopup />
      {product.orderCompleted && (
        <PostPurchaseEmails orderId={product.orderId} />
      )}
    </div>
  );
};

export default ProductDetailPage;
