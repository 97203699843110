import React from "react";

const ProductDescription = ({ description }) => {
  // console.log('🟡 ProductDescription received:', description || '❌ No description available.');

  if (!description) {
    return (
      <div className="product-description">
        <h2>Product Description</h2>
        <p>Loading description...</p>
      </div>
    );
  }

  return (
    <div className="product-description">
      <h2>Product Description</h2>
      <div dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );
};

export default ProductDescription;
