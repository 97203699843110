import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../api/axiosConfig";
import "./BundleFAQ.scss";

const BundleFAQ = ({ bundleId }) => {
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    const fetchFAQsForBundle = async () => {
      try {
        // Step 1: Get bundle to extract product IDs
        const bundleRes = await axiosInstance.get(`/products/bundles/${bundleId}/`);
        const productIds = bundleRes.data.products;

        // Step 2: Get all FAQs
        const faqRes = await axiosInstance.get(`/products/faqs/`);

        // Step 3: Filter FAQs based on:
        // - Match if any product in bundle is listed in FAQ
        // - OR it's a general FAQ (no specific products or product_type)
        const filtered = faqRes.data.filter((faq) => {
          const appliesToProducts =
            faq.products?.length > 0 && faq.products.some((id) => productIds.includes(id));
          const isGeneral = (!faq.products || faq.products.length === 0) && !faq.product_type;

          return appliesToProducts || isGeneral;
        });

        setFaqs(filtered);
      } catch (error) {
        console.error("Error fetching FAQs for bundle:", error);
      }
    };

    fetchFAQsForBundle();
  }, [bundleId]);

  if (faqs.length === 0) return null;

  return (
    <div className="product-faq">
      <h2 className="faq-title">Frequently Asked Questions</h2>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div key={faq.id} className="faq-item">
            <button
              className="faq-question"
              onClick={() => {
                const element = document.getElementById(`faq-answer-${index}`);
                const item = element?.closest(".faq-item");
                item?.classList.toggle("active");
              }}
            >
              {faq.question}
              <span className="chevron">▼</span>
            </button>
            <div className="faq-answer" id={`faq-answer-${index}`}>
              <p>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};  

export default BundleFAQ;
