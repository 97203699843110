import React from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet"; // Import React Helmet
import { Tabs, Tab, Container, Alert } from "react-bootstrap";
import NewFAQ from "../components/CustomerSupport/NewFAQ";
import LiveChat from "../components/CustomerSupport/LiveChat";
import ContactForm from "../components/CustomerSupport/ContactForm";
import SupportTickets from "../components/CustomerSupport/SupportTickets";
import SearchComponent from "../components/CustomerSupport/SearchComponent";
import { motion } from "framer-motion";
import "./CustomerSupport.scss";

const tabVariants = {
  hidden: { opacity: 0, x: -50 },
  visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
};

const CustomerSupport = () => {
  const token = useSelector((state) => state.user.token);
  const isAuthenticated = !!token;

  return (
    <Container className="customer-support">
      {/* Meta Tags for SEO */}
      <Helmet>
        <title>Customer Support | Ruoth</title>
        <meta
          name="description"
          content="Need assistance? Visit Ruoth's Customer Support for FAQs, live chat, and support tickets to help you with your inquiries."
        />
        <meta
          name="keywords"
          content="Ruoth customer support, FAQs, live chat, support tickets, contact us, customer service"
        />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Customer Support | Ruoth" />
        <meta
          property="og:description"
          content="Get the help you need with Ruoth's Customer Support. Access FAQs, live chat, and contact forms."
        />
        <meta
          property="og:image"
          content="https://www.ruothstore.com/assets/images/customer-support-banner.jpg"
        />
        <meta
          property="og:url"
          content="https://www.ruothstore.com/customer-support"
        />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:title" content="Customer Support | Ruoth" />
        <meta
          name="twitter:description"
          content="Need assistance? Reach out to Ruoth's Customer Support for quick help."
        />
        <meta
          name="twitter:image"
          content="https://www.ruothstore.com/assets/images/customer-support-banner.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Canonical Link */}
        <link
          rel="canonical"
          href="https://www.ruothstore.com/customer-support"
        />
      </Helmet>

      <h1 className="text-center mb-4">Customer Support</h1>
      <motion.div initial="hidden" animate="visible" variants={tabVariants}>
        <Tabs defaultActiveKey="faqs" id="support-tabs" className="mb-3 tabs">
          <Tab eventKey="faqs" title="FAQs">
            <NewFAQ />
          </Tab>
          <Tab eventKey="tickets" title="Support Tickets">
            {isAuthenticated ? (
              <>
                <SearchComponent />
                <SupportTickets isAuthenticated={isAuthenticated} />
              </>
            ) : (
              <Alert variant="warning" className="text-center">
                Please <a href="/login">log in</a> to view and manage your
                support tickets.
              </Alert>
            )}
          </Tab>
          <Tab eventKey="chat" title="Live Chat">
            <LiveChat />
          </Tab>
          <Tab eventKey="contact" title="Contact Us">
            <ContactForm />
          </Tab>
        </Tabs>
      </motion.div>
    </Container>
  );
};

export default CustomerSupport;
