import React, { useState, useEffect, useRef } from "react";
import { axiosInstance } from "../../api/axiosConfig";
import { FaClock, FaHourglassStart, FaExclamationTriangle } from "react-icons/fa";
import "./CountdownTimer.scss";

const CountdownTimer = ({ productId }) => {
  const [timeLeft, setTimeLeft] = useState(null);
  const [saleCampaign, setSaleCampaign] = useState("Limited-Time Offer");
  const [loading, setLoading] = useState(true);
  const [saleEnded, setSaleEnded] = useState(false);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (!productId) {
      console.error("CountdownTimer Error: Missing productId");
      setLoading(false);
      return;
    }

    console.log("Fetching countdown for productId:", productId);

    const fetchCountdown = async () => {
      try {
        const response = await axiosInstance.get(`/products/products/${productId}/scarcity/`);
        const { countdown_timer, campaign_message } = response.data;

        console.log("✅ API Response:", response.data);

        setSaleCampaign(campaign_message || "Limited-Time Offer");

        const timeRemaining = Math.floor(countdown_timer); // Convert float to integer

        if (timeRemaining > 0) {
          startCountdown(timeRemaining);
        } else {
          console.warn("🚫 No active countdown found, marking sale as ended.");
          setSaleEnded(true);
        }
      } catch (error) {
        console.error("❌ API Request Failed:", error);
        setSaleEnded(true);
      } finally {
        setLoading(false);
      }
    };

    fetchCountdown();

    return () => clearInterval(intervalRef.current); // Cleanup interval on unmount
  }, [productId]);

  const calculateTimeLeft = (secondsLeft) => {
    if (secondsLeft > 0) {
      return {
        days: Math.floor(secondsLeft / (60 * 60 * 24)),
        hours: Math.floor((secondsLeft / (60 * 60)) % 24),
        minutes: Math.floor((secondsLeft / 60) % 60),
        seconds: Math.floor(secondsLeft % 60),
      };
    } else {
      setSaleEnded(true);
      return null;
    }
  };

  const startCountdown = (secondsLeft) => {
    setTimeLeft(calculateTimeLeft(secondsLeft));

    intervalRef.current = setInterval(() => {
      secondsLeft -= 1;
      const newTimeLeft = calculateTimeLeft(secondsLeft);
      setTimeLeft(newTimeLeft);
      if (!newTimeLeft) {
        clearInterval(intervalRef.current);
        setSaleEnded(true);
      }
    }, 1000);
  };

  return (
    <div className="countdown-timer" role="timer" aria-live="polite">
      {loading ? (
        <div className="timer-loading">
          <FaHourglassStart className="timer-icon" aria-hidden="true" />
          <span className="sr-only">Loading countdown</span>
        </div>
      ) : saleEnded ? (
        <div className="timer-ended">
          <p className="empowering-message">
            {[
              "Every great creation begins with the right tools.",
              "Your craft is timeless, invest in what lasts.",
              "Precision. Passion. Perfection. It all starts here.",
              "The difference is in the details. Make yours extraordinary.",
              "Mastery is not rushed. It is crafted with intention.",
              "Designed for purpose"
            ][Math.floor(Math.random() * 5)]}
          </p>
        </div>

      ) : (
        <div className={`timer-display ${timeLeft?.hours < 1 && "urgent"}`}>
          <h3 className="sale-campaign-name">{saleCampaign}</h3>
          <FaClock className="timer-icon urgency-animation" aria-hidden="true" />

          {timeLeft?.days > 0 && (
            <div className="time-segment">
              <span className="time-value">{timeLeft.days}</span>
              <span className="time-label">Days</span>
            </div>
          )}

          <div className="time-segment">
            <span className="time-value">{timeLeft?.hours}</span>
            <span className="time-label">Hours</span>
          </div>
          <div className="time-segment">
            <span className="time-value">{timeLeft?.minutes}</span>
            <span className="time-label">Minutes</span>
          </div>
          <div className="time-segment">
            <span className="time-value">{timeLeft?.seconds}</span>
            <span className="time-label">Seconds</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default CountdownTimer;
