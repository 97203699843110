import { axiosInstance } from "../../api/axiosConfig";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchOrders = createAsyncThunk(
  "orders/fetchOrders",
  async (userId) => {
    const response = await axiosInstance.get(`/orders/user/${userId}`);
    return response.data;
  },
);

export const initiateReturn = createAsyncThunk(
  "orders/initiateReturn",
  async ({ orderId, reason }) => {
    const response = await axiosInstance.post(
      `/orders/${orderId}/initiate_return/`,
      { return_reason: reason },
    );
    return response.data;
  },
);

const orderSlice = createSlice({
  name: "order",
  initialState: {
    orders: [],
    status: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.orders = action.payload;
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(initiateReturn.pending, (state) => {
        state.status = "loading";
      })
      .addCase(initiateReturn.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Update the order's return status in the state
        const { orderId, returnData } = action.payload;
        const order = state.orders.find((order) => order.id === orderId);
        if (order) {
          order.items.forEach((item) => {
            if (item.id === returnData.order_item) {
              item.return_requested = true;
              item.return_status = returnData.status;
            }
          });
        }
      })
      .addCase(initiateReturn.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default orderSlice.reducer;
