import { axiosInstance } from "../api/axiosConfig";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { addToCart, removeFromCart } from "../features/cart/cartSlice";

import ProductImageGallery from "../components/productDetail/ProductImageGallery";
import PriceBreakdown from "../components/productDetail/PriceBreakdown";
import LiveStatsCounter from "../components/productDetail/LiveStatsCounter";
import CountdownTimer from "../components/productDetail/CountdownTimer";
import StickyBuyNow from "../components/productDetail/StickyBuyNow";
import ExpressCheckout from "../components/productDetail/ExpressCheckout";
import BundleIncludedProducts from "../components/bundleDetail/BundleIncludedProducts";
import BundleHighlights from "../components/bundleDetail/BundleHighlights";
import BundleFAQ from "../components/bundleDetail/BundleFAQ";
import SubscriptionUpsell from "../components/productDetail/SubscriptionUpsell";
import ExitIntentPopup from "../components/productDetail/ExitIntentPopup";
import SeoMetaTags from "../components/productDetail/SeoMetaTags";

import "./BundleDetailPage.scss";

const BundleDetailPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userInfo, token } = useSelector((state) => state.user);
  const cartItems = useSelector((state) => state.cart.items);

  const [bundle, setBundle] = useState(null);
  const [includedProducts, setIncludedProducts] = useState([]);
  const [isInCart, setIsInCart] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBundle = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(`/products/bundles/${id}/`);
        setBundle(response.data);

        // Optional: Fetch full product details for each product ID in the bundle
        const productPromises = response.data.products.map((productId) =>
          axiosInstance.get(`/products/products/${productId}/`)
        );
        const fullProducts = await Promise.all(productPromises);
        setIncludedProducts(fullProducts.map((res) => res.data));

      } catch (error) {
        console.error("Error fetching bundle:", error);
        navigate("/not-found");
      } finally {
        setLoading(false);
      }
    };

    fetchBundle();
  }, [id, navigate]);

  useEffect(() => {
    if (bundle) {
      setIsInCart(cartItems.some((item) => item.id === bundle.id));
    }
  }, [bundle, cartItems]);

  const handleCartToggle = () => {
    if (!bundle) return;

    if (isInCart) {
      dispatch(removeFromCart(bundle));
    } else {
      dispatch(addToCart(bundle));
    }
    setIsInCart(!isInCart);
  };

  const handleExpressCheckout = async (savePayment) => {
    if (!userInfo || !token) {
      navigate(`/login?redirect=${encodeURIComponent(window.location.pathname)}`);
      return;
    }

    try {
      const response = await axiosInstance.post("/orders/express_checkout/", {
        bundleId: bundle.id,
        savePayment,
      });

      if (response.data.success) {
        response.data.order_id
          ? navigate(`/order-confirmation/${response.data.order_id}`)
          : (window.location.href = response.data.checkoutUrl);
      } else {
        alert("Checkout failed. Try again.");
      }
    } catch (error) {
      console.error("Express Checkout Error:", error);
      alert("Network or API error during checkout.");
    }
  };

  if (loading) return <div>Loading bundle details...</div>;
  if (!bundle) return <div>Bundle not found.</div>;

  return (
    <div className="bundle-detail-page">
      <SeoMetaTags product={bundle} />
  
      {/* 🔝 High-Impact Above-the-Fold Section */}
      <div className="above-the-fold">
        <div className="left-column">
          <ProductImageGallery images={[bundle.thumbnail_image]} />
        </div>
        <div className="right-column">
          <h1>{bundle.name}</h1>
          <PriceBreakdown
            price={bundle.discount_price || bundle.price}
            originalPrice={bundle.discount_price ? bundle.price : null}
          />
  
          <CountdownTimer productId={bundle.id} />
          <LiveStatsCounter productId={bundle.id} />
  
          <div className="bundle-buttons">
            <button onClick={handleCartToggle}>
              {isInCart ? "Remove from Cart" : "Add to Cart"}
            </button>
          </div>
  
          <ExpressCheckout product={bundle} onExpressCheckout={handleExpressCheckout} />
          <StickyBuyNow product={bundle} />
        </div>
      </div>
  
      {/* ✅ Urgency & Highlight */}
      <div className="trust-zone">
        <CountdownTimer productId={bundle.id} />
        <BundleHighlights bundle={bundle} />
      </div>
  
      {/* 📦 Bundle Product Details */}
      <div className="product-details-section">
        <BundleIncludedProducts products={includedProducts} />
      </div>
  
      {/* ❓ FAQs styled same as PDP */}
      <div className="faqs-licensing">
        <div className="faqs-column">
          <BundleFAQ bundleId={bundle.id} />
        </div>
      </div>
  
      {/* 🚀 Upsell & Smart Add-ons */}
      <div className="upsells-section">
        <div className="upsell-row">
          <SubscriptionUpsell />
        </div>
      </div>
  
      <ExitIntentPopup />
    </div>
  );  
};

export default BundleDetailPage;
