// src/components/homePage/PromotionSlider.js
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/bundle"; // Import the full bundle for side effects (module registration)
import "swiper/css/bundle";
import { axiosInstance } from "../../api/axiosConfig";
import "./PromotionSlider.scss";

const PromotionSlider = () => {
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    const fetchSlides = async () => {
      const response = await axiosInstance.get("/hero-slides/hero-slides/");
      setSlides(response.data);
    };
    fetchSlides();
  }, []);

  return (
    <div className="promotion-slider">
      <Swiper
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 5000 }}
        loop
        effect="cube" // Experiment with "fade", "cube", etc.
        speed={800}
        parallax
        className="custom-swiper"
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className="slide">
              <img
                className="slide-image"
                src={slide.image}
                alt={slide.text}
                data-swiper-parallax="-20%"
              />
              <div className="slide-caption" data-swiper-parallax="-100">
                <h1>{slide.text}</h1>
                <a className="btn" href={slide.cta}>
                  Learn More
                </a>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default PromotionSlider;