import { axiosInstance } from "../api/axiosConfig";
// src/api/reviewApi.js

const API_URL = "/products/reviews/"; // Adjust the base URL if needed

export const submitReview = (reviewData) => {
  return axiosInstance.post(API_URL, reviewData);
};

export const fetchReviews = (productId) => {
  return axiosInstance.get(`${API_URL}?product=${productId}`);
};
