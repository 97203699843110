// src/components/homePage/Testimonials.js
import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { axiosInstance } from "../../api/axiosConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faStarHalfAlt,
  faStar as farStar,
} from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Testimonials.scss";

const Testimonials = () => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axiosInstance.get("/products/reviews/"); // Adjust the endpoint if needed
        setReviews(response.data);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };
    fetchReviews();
  }, []);

  // Reusable renderStars function
  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    return (
      <>
        {Array(fullStars)
          .fill()
          .map((_, index) => (
            <FontAwesomeIcon key={index} icon={faStar} className="star-icon" />
          ))}
        {halfStar && (
          <FontAwesomeIcon icon={faStarHalfAlt} className="star-icon" />
        )}
        {Array(emptyStars)
          .fill()
          .map((_, index) => (
            <FontAwesomeIcon
              key={index + fullStars + 1}
              icon={farStar}
              className="star-icon"
            />
          ))}
      </>
    );
  };

  return (
    <div className="testimonials-container">
      {reviews.map((review, index) => (
        <Card key={`${review.id}-${index}`} className="testimonial-card">
          <Card.Body className="card-body">
            <Card.Title className="card-title">
              {review.product.name}
            </Card.Title>
            <Card.Text className="card-text">
              "{review.comment}" - <strong>{review.user_display}</strong>{" "}
              {/* Display user_display */}
            </Card.Text>
            <Card.Footer className="card-footer">
              <div className="review-rating">{renderStars(review.rating)}</div>{" "}
              {/* Reuse renderStars */}
            </Card.Footer>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
};

export default Testimonials;
