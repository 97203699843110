import React from "react";
import { FiDownload, FiUnlock, FiLayers, FiRefreshCw, FiGift } from "react-icons/fi";
import "./ProductTicker.scss"; // New styles for ticker

const digitalHighlights = [
  { icon: <FiDownload />, text: "Instant Access – No Waiting" },
  { icon: <FiUnlock />, text: "Lifetime Use – No Expiry" },
  { icon: <FiLayers />, text: "Fully Editable & Customisable" },
  { icon: <FiRefreshCw />, text: "Free Updates for Future Enhancements" },
  { icon: <FiGift />, text: "Exclusive Bonus Designs in Select Bundles" },
  { icon: <FiDownload />, text: "Print at Home or Use Digitally" },
  { icon: <FiUnlock />, text: "No Software Required – Works Seamlessly" },
  { icon: <FiLayers />, text: "Available in Multiple Formats (PDF, PNG, JPG)" },
];

const physicalHighlights = [
  { icon: <FiGift />, text: "Crafted for Premium Quality & Durability" },
  { icon: <FiUnlock />, text: "Exclusive Limited Edition Pieces" },
  { icon: <FiLayers />, text: "Designed for Timeless Elegance" },
  { icon: <FiDownload />, text: "Perfectly Packaged for Gifting" },
  { icon: <FiRefreshCw />, text: "Handpicked Materials, Built to Last" },
  { icon: <FiGift />, text: "Adds Sophistication to Any Space" },
  { icon: <FiUnlock />, text: "Premium Quality, Ethically Sourced" },
];

const ProductTicker = ({ productType }) => {
  const displayHighlights = productType === "digital" ? digitalHighlights : physicalHighlights;

  return (
    <section className="product-ticker">
      <div className="ticker-wrapper">
        <div className="ticker-content">
          {displayHighlights.map((item, index) => (
            <div key={index} className="ticker-item">
              <span className="ticker-icon">{item.icon}</span>
              <span className="ticker-text">{item.text}</span>
            </div>
          ))}
          {/* Duplicate for seamless infinite scrolling */}
          {displayHighlights.map((item, index) => (
            <div key={`dup-${index}`} className="ticker-item">
              <span className="ticker-icon">{item.icon}</span>
              <span className="ticker-text">{item.text}</span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProductTicker;
