// src/pages/LoginComponent.js
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { loginUser, clearError } from "../features/user/userSlice";
import { useNavigate, Link, useLocation } from "react-router-dom"; // ✅ added useLocation
import { syncCartAndFavourites } from "../api/sync";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faFacebook } from "@fortawesome/free-brands-svg-icons";
import "./LoginComponent.scss";

const LoginComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation(); // ✅ to get current URL params

  const { status, userInfo, error, token } = useSelector((state) => state.user);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (userInfo && token) {
      const queryParams = new URLSearchParams(location.search);
      const redirectUrl = queryParams.get("redirect") || "/checkout"; // ✅ dynamic redirection

      setSuccessMessage("Login successful! Redirecting...");

      setTimeout(() => {
        syncCartAndFavourites(userInfo.id, dispatch);
        navigate(redirectUrl); // ✅ dynamically redirect user
      }, 1000);
    }
  }, [userInfo, token, navigate, dispatch, location.search]); // ✅ add location.search

  useEffect(() => {
    if (status === "succeeded") {
      console.log("Login succeeded");
    }
  }, [status]);

  useEffect(() => {
    if (error) {
      console.log("Login error:", error);
      setErrorMessage("Invalid email or password.");
    } else {
      setErrorMessage("");
    }
  }, [error]);

  const handleLogin = (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    dispatch(clearError());
    dispatch(loginUser({ email, password }));
  };

  return (
    <div className="login-page container">
      {/* Meta Tags for SEO */}
      <Helmet>
        <title>Login | Ruoth</title>
        <meta
          name="description"
          content="Access your Ruoth account to enjoy personalized services and manage your orders."
        />
        <meta
          name="keywords"
          content="Ruoth, login, sign in, user account, customer login"
        />
        <meta property="og:title" content="Login | Ruoth" />
        <meta
          property="og:description"
          content="Sign in to your Ruoth account to access exclusive features and offers."
        />
        <meta
          property="og:image"
          content="https://www.ruothstore.com/assets/images/login-banner.jpg"
        />
        <meta property="og:url" content="https://www.ruothstore.com/login" />
        <meta name="twitter:title" content="Login | Ruoth" />
        <meta
          name="twitter:description"
          content="Login to your Ruoth account to manage your orders and preferences."
        />
        <meta
          name="twitter:image"
          content="https://www.ruothstore.com/assets/images/login-banner.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href="https://www.ruothstore.com/login" />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <div className="login-card">
        <h2 className="text-center">Login</h2>
        {successMessage && (
          <div className="alert alert-success">{successMessage}</div>
        )}
        {errorMessage && (
          <div className="alert alert-danger">{errorMessage}</div>
        )}

        <form onSubmit={handleLogin}>
          <div className="form-group">
            <label htmlFor="email">Email address</label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              className="form-control"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary btn-block"
            disabled={status === "loading"}
          >
            {status === "loading" ? "Logging in..." : "Login"}
          </button>
        </form>

        <div className="social-login">
          <button type="button" className="btn btn-google">
            <FontAwesomeIcon icon={faGoogle} /> Login with Google
          </button>
          <button type="button" className="btn btn-facebook">
            <FontAwesomeIcon icon={faFacebook} /> Login with Facebook
          </button>
        </div>

        <div className="links text-center">
          <Link to="/forgot-password">Forgot Password?</Link>
          <p>
            Don't have an account? <Link to="/register">Register here</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginComponent;
