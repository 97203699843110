import React, { useState, useEffect } from "react";
import { axiosInstance } from "../../api/axiosConfig";
import { FiArrowRight, FiInstagram } from "react-icons/fi";
import "./CustomerPhotos.scss";
import { useNavigate } from "react-router-dom";

// TikTok Icon (inline SVG for styling consistency)
const TikTokIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginLeft: "4px", marginRight: "4px" }}
  >
    <path
      d="M16 3v2.79a4.6 4.6 0 0 0 1.97.43 4.78 4.78 0 0 0 .99-.1v3.09a7.5 7.5 0 0 1-3-.63V14c0 4.21-2.59 6.49-5.62 6.49A4.88 4.88 0 0 1 5 15.63c0-2.71 2.1-5 5.34-5 .34 0 .73.02 1.16.09v3.17c-.29-.04-.56-.07-.84-.07-1.28 0-2.42.79-2.42 2.12s1.01 2.13 2.22 2.13c1.19 0 2.34-.93 2.34-2.85V3H16Z"
      fill="#000"
    />
  </svg>
);

const CustomerPhotos = ({ productId }) => {
  const navigate = useNavigate();
  const [customerPhotos, setCustomerPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCustomerPhotos = async () => {
      try {
        const response = await axiosInstance.get(
          `/products/${productId}/customer-photos/`
        );
        setCustomerPhotos(response.data);
      } catch (err) {
        console.error("Error fetching customer photos:", err);
        setError("Could not load customer photos.");
      } finally {
        setLoading(false);
      }
    };

    fetchCustomerPhotos();
  }, [productId]);

  return (
    <section className="customer-photos" aria-label="Customer Social Proof">
      <h3 tabIndex={0}>See How Others Are Using This!</h3>

      {/* Spinner for loading */}
      {loading && (
        <div className="spinner-wrapper" aria-label="Loading customer photos">
          <div className="spinner" />
        </div>
      )}

      {/* Error message */}
      {!loading && error && (
        <p className="error-text">{error}</p>
      )}

      {/* Empty state */}
      {!loading && !error && customerPhotos.length === 0 && (
        <p className="no-photos">
          No customer photos yet – you could be the first!
        </p>
      )}

      {/* Photo Grid */}
      {!loading && !error && customerPhotos.length > 0 && (
        <div className="photo-grid">
          {customerPhotos.map((photo) => (
            <div key={photo.id} className="photo-card" tabIndex={0}>
              <img
                src={photo.url}
                alt={`Customer upload by ${photo.user || "a Ruoth customer"}`}
                loading="lazy"
                tabIndex={0}
                aria-label={`Customer photo by ${photo.user || "anonymous"}`}
              />
            </div>
          ))}
        </div>
      )}

      {/* Upload CTA */}
      <div className="upload-bar">
        <button
          onClick={() => navigate("/dashboard/upload-photos")}
          className="upload-button"
        >
          <FiArrowRight />
          Upload Your Photo
        </button>
        <span>
          Or tag us <FiInstagram /> <strong>#RuothInMyKitchen</strong>
          <TikTokIcon /> <strong>#RuothKitchenVibes</strong>
        </span>
      </div>
    </section>
  );
};

export default CustomerPhotos;
