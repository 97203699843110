import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "../../features/user/userSlice";
import "./UserProfile.scss";

const UserProfile = () => {
  const dispatch = useDispatch();
  const { userInfo, status, error } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  if (status === "loading") {
    return <p>Loading...</p>;
  }

  if (status === "failed") {
    return <p>Failed to load user data: {error}</p>;
  }

  const { username, email, address, phone_number } = userInfo || {};

  return (
    <div className="user-profile section">
      <h2>User Profile</h2>
      <p>Username: {username || "N/A"}</p>
      <p>Email: {email || "N/A"}</p>
      <p>Address: {address || "N/A"}</p>
      <p>Phone Number: {phone_number || "N/A"}</p>
    </div>
  );
};

export default UserProfile;
