import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import "./LiveChat.scss";

const LiveChat = () => {
  useEffect(() => {
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = "https://embed.tawk.to/669be77b32dca6db2cb2b963/1i38g0f3s";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();
  }, []);

  return (
    <Container className="live-chat-info text-center mb-4">
      <h2 className="text-center mb-4">Live Chat</h2>
      <p>
        The live chat widget is available at the bottom-right corner of your
        screen.
      </p>
      <p>
        Click on the chat icon to connect with our support team instantly. We
        are here to assist you 24/7!
      </p>
      <p>
        If you have any issues starting the chat, please try refreshing your
        browser or reach out to us via other support channels.
      </p>
    </Container>
  );
};

export default LiveChat;
