import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import FilterSidebar from "../components/productCatalogue/FilterSidebar";
import Pagination from "../components/productCatalogue/Pagination";
import ProductCard from "../components/productCatalogue/ProductCard";
import Welcome from "../components/productCatalogue/Welcome";
import { axiosInstance } from "../api/axiosConfig"; // Update import
import "./ProductCatalogue.scss";
import { meilisearchInstance } from "../api/axiosConfig";

const ProductCatalogueWithFilters = () => {
  const [products, setProducts] = useState([]);
  const [filters, setFilters] = useState({
    query: "",
    category: "",
    priceRange: [0, 100],
    brand: "",
    productType: "",
    ordering: "",
  });
  const [initialLoad, setInitialLoad] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isSidebarActive, setIsSidebarActive] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  // Mobile detection
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.matchMedia('(max-width: 992px)').matches);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Toggle sidebar visibility
  const toggleSidebar = () => {
    setIsSidebarActive(!isSidebarActive);
  };

  // Fetch products based on filters
  const fetchProducts = async (currentFilters, page = 1) => {
    try {
      const queryParams = new URLSearchParams({
        search: currentFilters.query || "",
        page,
        ...(currentFilters.category && { category: currentFilters.category }),
        ...(currentFilters.brand && { brand: currentFilters.brand }),
        ...(currentFilters.productType && { product_type: currentFilters.productType }),
        price_min: currentFilters.priceRange?.[0] ?? 0,
        price_max: currentFilters.priceRange?.[1] ?? 100,
      });

      const response = await axiosInstance.get(`/products/products/?${queryParams.toString()}`);
      const data = response.data;

      setProducts(data.results || []);
      setTotalPages(data.total_pages || 1);
    } catch (error) {
      console.error("❌ Error fetching products from Django API:", error);
      setProducts([]);
      setTotalPages(1);
    } finally {
      setInitialLoad(false);
    }
};


  useEffect(() => {
    if (initialLoad) {
      fetchProducts(filters);
    }
  }, [initialLoad, filters]);

  const handleFilterChange = (newFilters) => {
    console.log("Filters changed:", newFilters);
    setFilters(newFilters);
    fetchProducts(newFilters, 1);
    setCurrentPage(1);
    if (isMobile) toggleSidebar();
  };

  const handlePageChange = (page) => {
    console.log("Page changed to:", page);
    setCurrentPage(page);
    fetchProducts(filters, page);
  };

  return (
    <div className="product-catalogue">
      <Helmet>
        <title>Product Catalogue | Ruoth</title>
        <meta
          name="description"
          content="Explore Ruoth's extensive range of high-quality culinary tools designed to empower creativity and precision in the kitchen. Find the perfect tools for your culinary needs."
        />
        <meta
          name="keywords"
          content="Ruoth products, culinary tools, kitchen innovation, professional kitchen equipment, cake scrapers, sieves, cooking utensils, product catalogue"
        />
        <meta property="og:title" content="Product Catalogue | Ruoth" />
        <meta
          property="og:description"
          content="Browse Ruoth’s premium selection of physical and digital culinary tools. Elevate your kitchen creativity with our innovative products."
        />
        <meta
          property="og:image"
          content="https://www.ruothstore.com/path-to-catalogue-image.jpg"
        />
        <meta
          property="og:url"
          content="https://www.ruothstore.com/catalogue"
        />
        <meta name="twitter:title" content="Product Catalogue | Ruoth" />
        <meta
          name="twitter:description"
          content="Discover Ruoth’s high-quality culinary tools designed for professionals and enthusiasts alike."
        />
        <meta
          name="twitter:image"
          content="https://www.ruothstore.com/path-to-catalogue-image.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href="https://www.ruothstore.com/catalogue" />
      </Helmet>

      {/* Mobile Filter Toggle */}
      {isMobile && (
        <button 
          className="mobile-filter-toggle"
          onClick={toggleSidebar}
        >
          Filter Products
        </button>
      )}

      {/* Mobile Overlay */}
      <div 
        className={`mobile-overlay ${isSidebarActive ? 'active' : ''}`}
        onClick={toggleSidebar}
      />

      <div className="product-catalogue__grid">
        {/* Filter Sidebar */}
        <div 
          className={`product-catalogue__sidebar ${
            isSidebarActive ? 'active' : ''
          }`}
        >
          <FilterSidebar 
            onChange={handleFilterChange}
            onClose={toggleSidebar}
          />
        </div>
        
        {/* Main Content */}
        <main className="product-catalogue__main">
          <div className="product-list">
            <Welcome />
            
            {!initialLoad && products?.length > 0 ? (
              <div className="product-grid">
                {products.map((product) => (
                  <article key={product.id} className="product-card__wrapper">
                    <ProductCard product={product} />
                  </article>
                ))}
              </div>
            ) : (
              !initialLoad && <p className="product-list__empty">No products found</p>
            )}
            
            {!initialLoad && (
              <div className="product-pagination">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default ProductCatalogueWithFilters;