import { axiosInstance } from "../api/axiosConfig";

// Existing fetch functions
export const fetchArticles = () => axiosInstance.get("/content/articles/");
export const fetchGuides = () => axiosInstance.get("/content/guides/");
export const fetchVideos = () => axiosInstance.get("/content/videos/");
export const fetchContentDetail = (id) =>
  axiosInstance.get(`/content/content/${id}/`); // Ensure consistent path
export const fetchComments = (contentId) =>
  axiosInstance.get(`/content/comments/?content=${contentId}`);
export const fetchTags = () => axiosInstance.get("/content/tags/");
export const fetchRatings = (contentId) =>
  axiosInstance.get(`/content/ratings/?content=${contentId}`);
export const searchContent = (query) =>
  axiosInstance.get(`/content/search/?q=${query}`);

// New fetch functions for additional content types
export const fetchWhitePapers = () =>
  axiosInstance.get("/content/white-papers/");
export const fetchCaseStudies = () =>
  axiosInstance.get("/content/case-studies/");
export const fetchEbooks = () => axiosInstance.get("/content/ebooks/");
export const fetchResearchReports = () =>
  axiosInstance.get("/content/research-reports/");
export const fetchThoughtLeadership = () =>
  axiosInstance.get("/content/thought-leadership/");
export const fetchTechnicalManuals = () =>
  axiosInstance.get("/content/technical-manuals/");
export const fetchNewsletters = () =>
  axiosInstance.get("/content/newsletters/");
export const fetchPresentations = () =>
  axiosInstance.get("/content/presentations/");
export const fetchPressReleases = () =>
  axiosInstance.get("/content/press-releases/");

// New functions for interacting with content
export const likeContent = (contentId) =>
  axiosInstance.post(`/content/content/${contentId}/like/`); // Adjusted path for consistency
export const likeComment = (commentId) =>
  axiosInstance.post(`/content/comments/${commentId}/like/`);
export const postComment = (contentId, commentData) =>
  axiosInstance.post(`/content/content/${contentId}/comments/`, commentData); // Adjusted path for consistency
export const postRating = (contentId, ratingData) =>
  axiosInstance.post(`/content/content/${contentId}/ratings/`, ratingData); // Adjusted path for consistency

const contentApi = {
  fetchArticles,
  fetchGuides,
  fetchVideos,
  fetchContentDetail,
  fetchComments,
  fetchTags,
  fetchRatings,
  searchContent,
  fetchWhitePapers,
  fetchCaseStudies,
  fetchEbooks,
  fetchResearchReports,
  fetchThoughtLeadership,
  fetchTechnicalManuals,
  fetchNewsletters,
  fetchPresentations,
  fetchPressReleases,
  likeContent, // Liking content
  likeComment, // Liking comments
  postComment, // Posting comments
  postRating, // Posting ratings
};

export default contentApi;
