import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./LoginOrGuestModal.scss";

const LoginOrGuestModal = ({
  show,
  handleClose,
  handleLogin,
  handleRegister,
  handleGuest,
}) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Proceed to Checkout</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Please choose an option to continue:</p>
        <Button variant="primary" onClick={handleLogin} block>
          Log In
        </Button>
        <Button variant="secondary" onClick={handleRegister} block>
          Register
        </Button>
        <Button variant="outline-primary" onClick={handleGuest} block>
          Continue as Guest
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default LoginOrGuestModal;
