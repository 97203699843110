import React from "react";
import "./CartReview.scss";

const CartReview = ({
  cart,
  email,
  setEmail,
  shippingAddress = {},
  handleAddressChange,
  nextStep,
}) => {
  return (
    <div className="cart-review">
      <h2 className="cart-review-title">Review Your Cart</h2>
      <div className="cart-items">
        {cart.map((product) => (
          <div key={product.id} className="cart-item">
            <img
              src={product.thumbnail_image}
              alt={product.name}
              className="cart-item-image"
            />
            <div className="cart-item-info">
              <h3 className="cart-item-name">{product.name}</h3>
              <p className="cart-item-price">Price: £{product.price}</p>
            </div>
          </div>
        ))}
      </div>
      <form className="cart-review-form">
        <label>Email:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <label>Address Line 1:</label>
        <input
          type="text"
          name="address_line1"
          value={shippingAddress.address_line1 || ""}
          onChange={handleAddressChange}
          required
        />
        <label>Address Line 2:</label>
        <input
          type="text"
          name="address_line2"
          value={shippingAddress.address_line2 || ""}
          onChange={handleAddressChange}
        />
        <label>City:</label>
        <input
          type="text"
          name="city"
          value={shippingAddress.city || ""}
          onChange={handleAddressChange}
          required
        />
        <label>County:</label>
        <input
          type="text"
          name="county"
          value={shippingAddress.county || ""}
          onChange={handleAddressChange}
        />
        <label>Post Code:</label>
        <input
          type="text"
          name="post_code"
          value={shippingAddress.post_code || ""}
          onChange={handleAddressChange}
          required
        />
        <label>Country:</label>
        <input
          type="text"
          name="country"
          value={shippingAddress.country || ""}
          onChange={handleAddressChange}
          required
        />
      </form>
      <button onClick={nextStep} className="next-step-button">
        Next Step
      </button>
    </div>
  );
};

export default CartReview;
